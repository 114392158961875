import React from "react";
import styled from "styled-components";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import ArrowRightAltRoundedIcon from "@mui/icons-material/ArrowRightAltRounded";

import MenuList from "@mui/material/MenuList";
const InputWrapper = styled.div`
  width: 100%;
`;
const Input = styled.input`
  width: 100%;
  height: 60px;
  border: none;
  text-align: left;
  outline: none;
  margin-left: 10px;
  font-size: 18px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  :focus-within {
    outline: none;
  }
  @media (max-width: 900px) {
    height: 45px;
  }
`;
const InputLabel = styled.label`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #27153e;
  margin-bottom: 15px;
  text-transform: capitalize;
  margin-left: 5px;
`;
const InputCont = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  border-radius: 7px;
  background: #fff;
  margin-top: 5px;
  overflow: hidden;
  border: 1px solid #bababa;
  @media (max-width: 900px) {
    height: 45px;
  }
`;
const InputCurrency = styled.div`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #b5a8c6;
  margin-right: 26px;
  @media (max-width: 900px) {
    margin-right: 10px;
  }
`;
const MenuButton = styled.button`
  background: #fff;
  border: none;
  width: 115px;
  border-left: 1px solid #bababa;
  // border-radius: 7px 0px 0px 7px;
  height: 60px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  color: #27153e;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  @media (max-width: 900px) {
    height: 45px;
    font-size: 16px;
  }
`;

const MenuCont = styled.div`
  width: 113px;
  background: #fff;
  border-radius: 0px 0px 7px 7px;
  border: 1px solid #bababa;
  margin-top: 0px;
`;
const ExchangeIcon = styled.div`
  margin: 25px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
interface Props {
  currency: string;
  setCurrency: (currency: string) => void;
  price: number;
  value: string;
  setValue: (value: string) => void;
  value2: string;
  setValue2: (value: string) => void;
}

export default function InputComponent({
  currency,
  setCurrency,
  price,
  value,
  setValue,
  value2,
  setValue2,
}: Props) {
  const [open, setOpen] = React.useState(false);

  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const anchorRef2 = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleItemClick = (
    event: Event | React.SyntheticEvent,
    currency: string | undefined
  ) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    if (currency) {
      setCurrency(currency);
    }
    setValue("");
    setValue2("");
    setOpen(false);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  function handleInputOnchange(event: React.ChangeEvent<HTMLInputElement>) {
    // only accept positive numbers

    const value = event.target.value.replace(
      /[^[1-9]\d{0,2}(\.\d{3})*(,\d+)?$]/g,
      ""
    );
    if (event.target.value.match(/^[0-9]*$/)) {
      setValue(value);
      setValue2((Number(value) / price).toFixed(4));
    }
  }
  function handleGrammInputOnchange(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    // only accept numbers
    const value = event.target.value.replace(
      /[^[1-9]\d{0,2}(\.\d{3})*(,\d+)?$]/g,
      ""
    );
    if (event.target.value.match(/^[0-9]*$/)) {
      setValue2(value);
      setValue((Number(value) * price).toFixed(4));
    }
  }

  return (
    <>
      <InputWrapper>
        <InputLabel>Amount</InputLabel>
        <InputCont>
          <div>
            <MenuButton
              ref={anchorRef}
              onClick={handleToggle}
              className="currency-button"
              disabled={false}
            >
              {currency}
              <ArrowDropDownIcon sx={{ fontSize: "2rem" }} />
            </MenuButton>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              placement="bottom-start"
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom-start" ? "left top" : "left bottom",
                  }}
                >
                  <MenuCont>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={open}
                        id="composition-menu"
                        aria-labelledby="composition-button"
                        onKeyDown={handleListKeyDown}
                      >
                        <MenuItem onClick={(e) => handleItemClick(e, "₹ INR")}>
                          ₹ INR
                        </MenuItem>
                        <MenuItem onClick={(e) => handleItemClick(e, "$ USD")}>
                          $ USD
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </MenuCont>
                </Grow>
              )}
            </Popper>
          </div>
          <Input type="number" value={value} onChange={handleInputOnchange} />
          <InputCurrency>
            {currency === "$ USD" ? "Dollars" : "Rupees"}
          </InputCurrency>
        </InputCont>
      </InputWrapper>

      <ExchangeIcon>
        <ArrowRightAltRoundedIcon
          sx={{
            color: "#27153E",
            transform: "rotate(90deg)",
            fontSize: "3rem",
            marginLeft: "-2rem",
          }}
        />
      </ExchangeIcon>
      <InputWrapper>
        <InputLabel>Quantity</InputLabel>
        <InputCont>
          <div>
            <Popper
              open={false}
              anchorEl={anchorRef2.current}
              role={undefined}
              placement="bottom-start"
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom-start" ? "left top" : "left bottom",
                  }}
                >
                  <MenuCont>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={open}
                        id="composition-menu"
                        aria-labelledby="composition-button"
                        onKeyDown={handleListKeyDown}
                      >
                        <MenuItem onClick={(e) => handleItemClick(e, "₹ INR")}>
                          ₹ INR
                        </MenuItem>
                        <MenuItem onClick={(e) => handleItemClick(e, "$ USD")}>
                          $ USD
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </MenuCont>
                </Grow>
              )}
            </Popper>
          </div>
          <Input
            type="number"
            value={value2}
            onChange={handleGrammInputOnchange}
          />
          <InputCurrency>Grams</InputCurrency>
          <MenuButton
            ref={anchorRef2}
            onClick={handleToggle}
            className="currency-button"
            disabled
          >
            GM
          </MenuButton>
        </InputCont>
      </InputWrapper>
    </>
  );
}
