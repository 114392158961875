// google re-captcha v2 key
// export const RECAPTCHA_KEY = "6LdXJMkhAAAAAAcQS13i9S4J45yVdSLMug0Nf_Dj";
export const RECAPTCHA_KEY = "6LdK8_wUAAAAALwZnulMvCMWnRkRkmtwM7S6SRkQ";

export const isBrowser = typeof window !== "undefined";

export interface IGoogleReCaptcha {
  execute: () => void;
  reset: () => void;
  getResponse: () => string;
}

export interface IProjectWindow extends Window {
  grecaptcha: IGoogleReCaptcha;
  grecaptchaTokenResponse: (token: string) => void;
  grecaptchaExpired: () => void;
}
