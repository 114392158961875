import React from "react";
import styled from "styled-components";
import logo from "../../assets/images/Logo.png";
import linkedInImg from "../../assets/images/linkedin.png";
import twitterImg from "../../assets/images/twitter.png";
import telegramImg from "../../assets/images/telegram.png";
import { Link } from "react-router-dom";
const Wrapper = styled.div`
  max-width: 100%;
  position: relative;
  padding: 50px;
  z-index: 0;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 30px;
  @media (max-width: 900px) {
    padding: 25px;
    flex-wrap: wrap;
  }
`;
const LogoImg = styled.img`
  object-fit: contain;
`;
const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 18px;
`;

const About = styled.p`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  width: 445px;
  color: #27153e;
  @media (max-width: 900px) {
    width: 100%;
  }
`;
const Socials = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
`;
const SocialItem = styled.img`
  width: 37px;
  height: 37px;
  cursor: pointer;
`;
const Title = styled.h1`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;

  color: #27153e;
`;

const LinkItem = styled.a`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #27153e;
  cursor: pointer;
  text-decoration: none;
`;
const CopyRight = styled.div`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  height: 65px;
  width: 100%;
  background: #01bbb7;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <>
      <Wrapper>
        <Row>
          <Link to="/">
            <LogoImg src={logo} alt="logo" />
          </Link>
          <About>
            OroPocket gives you direct ownership of vaulted Gold and Silver. Get
            24/7 access to your assets, best-in-class user experience, and peace
            of mind that comes through dealing with industry leaders.
          </About>
          <Socials>
            <LinkItem href="https://twitter.com/oropocket" target="_blank">
              <SocialItem src={twitterImg} />
            </LinkItem>
            {/* <LinkItem href="https://t.me/oropocket" target="_blank">
              <SocialItem src={telegramImg} />
            </LinkItem> */}
            <LinkItem
              href="https://www.linkedin.com/company/oropocket/"
              target="_blank"
            >
              <SocialItem src={linkedInImg} />
            </LinkItem>
          </Socials>
        </Row>
        <Row>
          <Title>Support</Title>
          <Link to="/Contact">
            <LinkItem>Contact Us</LinkItem>
          </Link>
          <Link to="/privacy_policy">
            <LinkItem>Privacy Policy</LinkItem>
          </Link>

          <LinkItem href="https://my.oropocket.com/login.php" target="_blank">
            <LinkItem>Ticket</LinkItem>
          </LinkItem>
          <Link to="/events">
            <LinkItem>Events</LinkItem>
          </Link>
        </Row>

        <Row>
          <Title>Links</Title>
          <LinkItem href="https://blog.oropocket.com/" target="_blank">
            Blog
          </LinkItem>
          <LinkItem href="https://blog.oropocket.com/category/knowledge/">
            Knowledge Base
          </LinkItem>
          <LinkItem href="https://blog.oropocket.com/category/trends/">
            Trends
          </LinkItem>
          <LinkItem href="https://www.youtube.com/channel/UCg0P-Weto2bGBXq2rGUAefw">
            Video Tutorials
          </LinkItem>
          {/* <LinkItem href="https://www.notion.so/unifarm/FAQs-6c78de55764648d09cc9ada0ca55785a">
          </LinkItem> */}
          <Link to="/Faq">
            <LinkItem>FAQ</LinkItem>
          </Link>
        </Row>

        <Row>
          <Title>Legal</Title>
          <Link to="/Refund_Policy">
            <LinkItem>Refund Policy</LinkItem>
          </Link>

          <Link to="/Terms_and_Conditions">
            <LinkItem>Terms & Condition</LinkItem>
          </Link>

          {/* <LinkItem
            href="https://docs.google.com/forms/d/1zZU_AXQhTh2agkce5xh30OIpQap6OFAj4VIWQyQGuJ8/viewform?ts=620a391c&edit_requested=true"
            target="_blank"
          > */}
          <Link to="/grievence">
            <LinkItem>Grievance Redressal</LinkItem>
          </Link>
          {/* </LinkItem> */}
        </Row>
      </Wrapper>
      <CopyRight>Copyright ©{currentYear}, OroPocket</CopyRight>
    </>
  );
}
