import React from "react";
import styled from "styled-components";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import bgImg from "../assets/images/background1.png";
import avatarIcon from "../assets/images/avatar.svg";
import mailIcon from "../assets/images/mail.svg";
import closedMailIcon from "../assets/images/closedMail.svg";
import contactIcon from "../assets/images/contact.svg";
import ArrowCircleUpRoundedIcon from "@mui/icons-material/ArrowCircleUpRounded";
const Wrapper = styled.div`
  max-width: 100%;
  position: relative;
  z-index: 22;
  overflow: hidden;
  @media (max-width: 900px) {
    width: 100%;
    max-width: unset;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  @media (max-width: 900px) {
    flex-direction: column;
    padding: 0px 25px;
  }
`;
const Row = styled.div`
  display: flex;
  width: 45%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 42px;
  padding-left: 50px;
  margin-bottom: 80px;
  @media (max-width: 900px) {
    width: 100%;
    padding: 0px;
    gap: 0px;
  }
`;
const Title = styled.h1`
  font-family: "Inter", sans-serif;
  font-style: normal;
  width: 100%;
  font-weight: 700;
  font-size: 58.9767px;
  line-height: 71px;
  text-align: left;
  color: #309b9b;
  @media (max-width: 900px) {
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    margin-bottom: 20px;
  }
`;
const Description = styled.p`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15.7271px;
  line-height: 18px;
  @media (max-width: 900px) {
    text-align: center;
  }
`;

const BackgroundImg = styled.img`
  position: absolute;
  right: 0;
  top: -10%;
  object-fit: cover;
`;
const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 11;
  padding: 40px 45px;
  width: 450px;
  background: linear-gradient(0deg, #edfbfa, #edfbfa);
  border: 0.472076px solid #6a6a6a;
  box-shadow: 4.08944px 4.08944px 13.9041px rgba(0, 0, 0, 0.2);
  border-radius: 20.0383px;
  gap: 15px;
  @media (max-width: 900px) {
    padding: 35px 25px;
    max-width: 90%;
  }
`;

const FormInput = styled.input`
  border: none;
  background: #fff;
  width: 100%;
  height: 60px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 7px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #6a6a6a;
  :focus-within {
    outline: none;
  }
  @media (max-width: 900px) {
    height: 40px;
  }
`;
const FormButton = styled.button`
  background: #00bead;
  box-shadow: -2px 0px 24px rgba(0, 190, 173, 0.37);
  border-radius: 12px 12px 0px 12px;
  width: 150px;
  border: none;
  height: 49px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  cursor: pointer;
  margin-top: 10px;
  @media (max-width: 900px) {
    height: 45px;
  }
`;

const InputItem = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 15px;
`;
const InputIcon = styled.img``;
const AttachButton = styled.button`
  border: 1px solid #00bead;
  border-radius: 34.4031px;
  background: transparent;
  padding: 9.82946px 18px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15.7271px;
  line-height: 18px;
  color: #00bead;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  cursor: pointer;
  height: 55px;
  width: 100%;
  @media (max-width: 900px) {
    height: 38px;
    font-size: 15px;
  }
`;
const InputLabel = styled.p`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  color: #27153e;
`;
export default function Redrassal() {
  return (
    <Wrapper>
      <Navbar />
      <BackgroundImg src={bgImg} alt="background" />
      <Container>
        <Row>
          <Title>Grievance Redressal</Title>
          <Description>
            For complaints/grievances please share the information in below
            mentioned format. Please note that the shared information will be
            confidential and appropriate action will be taken with utmost
            priority
          </Description>
          <Description>
            The name and photo associated with your Google account will be
            recorded when you upload files and submit this form. Only the email
            you enter is part of your response.
          </Description>
        </Row>
        <Row>
          <Form>
            <InputItem>
              <InputLabel>Email Address</InputLabel>
              <FormInput />
            </InputItem>
            <InputItem>
              <InputLabel>Full Name</InputLabel>
              <FormInput />
            </InputItem>
            <InputItem>
              <InputLabel>Contact Number</InputLabel>
              <FormInput />
            </InputItem>
            <InputItem>
              <InputLabel> Please Add Issue</InputLabel>

              <FormInput />
            </InputItem>
            <AttachButton>
              Attach File <ArrowCircleUpRoundedIcon sx={{ fontSize: "24px" }} />
            </AttachButton>
            <FormButton>Send </FormButton>
          </Form>
        </Row>
      </Container>
      <Footer />
    </Wrapper>
  );
}
