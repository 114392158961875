import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Navbar from "../components/Navbar";
import bgImg from "../assets/images/background1.png";
import { InputBox } from "../components/InputBox/InputBox";
import Footer from "../components/Footer";
import validator from "validator";

// import { HiOutlineUpload} from 'react-icons/hi';
import UploadIcon from "@mui/icons-material/Upload";
import CloseIcon from "@mui/icons-material/Close";

const Wrapper = styled.div`
  max-width: 100%;
  position: relative;
  z-index: 22;
  overflow: hidden;
  @media (max-width: 900px) {
    width: 100%;
    max-width: unset;
  }
`;

const BackgroundImg = styled.img`
  position: absolute;
  right: 0;
  top: -10%;
  object-fit: cover;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 0 25px;
  @media (max-width: 900px) {
    flex-direction: column;
    padding: 0px 25px;
  }
`;

const Row = styled.div`
  display: flex;
  width: 45%;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 18px;
  position: relative;
  @media (max-width: 900px) {
    width: 100%;
    padding: 0px;
    gap: 20px;
    align-items: center;
  }
`;

const FormButton = styled.button`
  background: #00bead;
  box-shadow: -2px 0px 24px rgba(0, 190, 173, 0.37);
  border-radius: 12px 12px 0px 12px;
  width: 150px;
  border: none;
  height: 49px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  cursor: pointer;
  margin-top: 10px;
  @media (max-width: 900px) {
    height: 45px;
  }
`;
const UploadWrapper = styled.div`
  //   border: 1px solid black;
  background: #00bead;
  border-radius: 12px 12px 0px 12px;
  padding: 15px;
  display: flex;
  justify-content: center;
  color: #ffffff;
  align-items: center;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
`;

const CancelWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Text = styled.p`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #00bead;
`;

const Error = styled(Text)`
  color: red;
`;

type fileType = {
  name: string;
};

type ErrorProps = {
  name?: boolean;
  number?: boolean;
  email?: boolean;
  issue?: boolean;
};

export const Grievence = () => {
  const [attachment, setAtachment] = useState<fileType>();
  const [error, setError] = useState<ErrorProps>({
    name: false,
    number: false,
    email: false,
    issue: false,
  });
  const [attachmentLabel, setAttachmentLable] = useState<any>("");
  const [success, setSuccess] = useState(false);
  // const [error, setError] = useState({
  //   name: false,
  //   email: false,
  //   cont_no: false,
  //   issue: false,
  // });
  const [formData, setFormData] = useState<any>("");

  const handleChange = (e: any) => {
    const file = e.target.files[0];
    setAtachment(e.target.files[0]);
    setFormData({
      ...formData,
      file,
    });
  };

  const handleFormInputChange = (e: any) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });

    // if (formData.name) {
    //   setError({ name: false });
    // }
    // if (validator.isEmail(formData?.email)) {
    //   setError({ email: false });
    // }

    // if (formData.number.length === 10 && formData.number.length < 11) {
    //   setError({ number: false });
    // }
  };

  const clearField = () => {
    setAtachment(undefined);
    setFormData({
      name: "",
      email: "",
      issue: "",
      number: "",
    });
  };

  const handleSubmit = async (e: any) => {
    const { name, email, issue, number } = formData;
    // console.log(name);
    // console.log(formData);
    e.preventDefault();

    if (
      name === undefined &&
      email === undefined &&
      issue === undefined &&
      number === undefined
    ) {
      console.log("called");
      setError({ name: true, email: true, number: true, issue: true });
    } else if (email === undefined || validator.isEmail(email) === false) {
      // console.log("va");
      setError({ name: false, email: true, number: false, issue: false });

      console.log("invalid Email address");
    } else if (
      number === undefined ||
      number.length !== 10 ||
      number.length > 11
    ) {
      setError({ name: false, email: false, number: true, issue: false });
    } else {
      const myHeaders = new Headers();

      myHeaders.append("X-Requested-With", "XMLHttpRequest");
      const formMData = new FormData();

      formMData.append("mauticform[formId]", "11");
      formMData.append("mauticform[return]", "");
      formMData.append("mauticform[f_name]", formData.name);
      formMData.append("mauticform[email]", formData.email);
      formMData.append("mauticform[issue]", formData.issue);
      formMData.append("mauticform[contact_number]", formData.number);
      formMData.append("mauticform[attachment]", formData.file);

      fetch("https://e.oropocket.com/index.php/form/submit", {
        method: "POST",
        headers: myHeaders,
        body: formMData,
        credentials: "same-origin",
      })
        .then((res) => {
          setSuccess(true);
          setError({ name: false, email: false, number: false, issue: false });

          clearField();
          console.log(res, "success");
        })
        .catch((err) => {
          console.log(err, "Error");
        });
    }
  };

  useEffect(() => {
    setAttachmentLable(attachment?.name);
  }, [attachment]);

  return (
    <Wrapper>
      <Navbar />
      <BackgroundImg src={bgImg} alt="background" />
      <Container>
        <Row>
          {error.name && error.email && error.number && error.issue ? (
            <Error>Please,fill empty fields</Error>
          ) : null}
          <InputBox
            label="Name"
            type="text"
            name="name"
            value={formData.name}
            onChange={handleFormInputChange}
          />

          {error.email ? <Error>Invalid Email</Error> : null}
          <InputBox
            label="Email"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleFormInputChange}
          />
          {error.number ? <Error>Invalid Contact Number</Error> : null}
          <InputBox
            label="Contact Number"
            type="text"
            name="number"
            value={formData.number}
            onChange={handleFormInputChange}
          />
          <InputBox
            label="Please address your issue"
            type="text"
            name="issue"
            value={formData.issue}
            onChange={handleFormInputChange}
          />
          <InputBox
            label="Attachment ,if any"
            type="file"
            id="upload"
            name="attachment"
            // value={formData.attachment}
            onChange={handleChange}
          />
          <div style={{ display: "flex" }}>
            <label htmlFor="upload">
              <UploadWrapper>
                <UploadIcon /> {attachment ? attachmentLabel : "Add File"}
              </UploadWrapper>
            </label>
            {attachment ? (
              <CancelWrapper
                onClick={() => {
                  setAtachment(undefined);
                }}
              >
                <CloseIcon />
              </CancelWrapper>
            ) : null}
          </div>

          {success ? <Text>Your response has been recorded </Text> : null}

          <FormButton onClick={handleSubmit}>Submit</FormButton>
        </Row>
      </Container>
      <Footer />
    </Wrapper>
  );
};
