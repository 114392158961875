import React from "react";
import styled from "styled-components";
import bgImg from "../../assets/images/background1.png";
import investImg from "../../assets/images/investImage.png";
import investImg2 from "../../assets/images/InvestImage2.png";
import investImg3 from "../../assets/images/InvestImage3.png";
import investImg4 from "../../assets/images/InvestImage4.png";

const BackgroundImg = styled.img`
  position: absolute;
  right: 0%;
  top: -35%;
  object-fit: cover;
  z-index: 0;
  @media (max-width: 900px) {
    display: none;
  }
`;
const Wrapper = styled.div`
  max-width: 100%;
  position: relative;
  padding: 50px;
  z-index: 1;
  @media (max-width: 900px) {
    padding: 25px;
  }
`;
const Title = styled.h1`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 61px;
  max-width: 70%;
  margin: 0 auto;
  text-align: center;
  background: linear-gradient(267.19deg, #00bcb3 5.03%, #01bbb7 5.03%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  @media (max-width: 900px) {
    font-size: 28px;
    line-height: 36px;
  }
`;
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 80px 0px;
  @media (max-width: 900px) {
    margin: 38px 0px;
    flex-direction: column;
  }
`;
const Row = styled.div`
  display: flex;
  position: relative;
  width: 50%;
  @media (max-width: 900px) {
    width: 100%;
  }
`;
const Image = styled.img`
  z-index: 5;
  margin-left: 10%;
  max-width: 100%;
  @media (max-width: 900px) {
    max-width: 60%;
    margin-left: 0px;
  }
`;
const Steps = styled.div`
  display: flex;
  align-items: Flex-start;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 900px) {
    gap: 10px;
    width: 100%;
    flex-direction: row;
  }
`;
const Step = styled.div`
  display: flex;
  gap: 35px;
  margin-top: 25px;
  flex-direction: Row;
  align-items: center;
`;
const StepIndex = styled.div<{ active?: boolean }>`
  width: 44px;
  height: 44px;
  background: #00bbbc;
  border-radius: 40px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #f3f7fa;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ active }) => !active && `opacity: 0.3;`}
  @media (max-width: 900px) {
    width: 22px;
    height: 22px;
    font-size: 14px;
  }
`;
const StepTitle = styled.h2`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #27153e;
  @media (max-width: 900px) {
    font-size: 18px;
    line-height: 26px;
  }
`;
const StepDescription = styled.p`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #27153e;
  @media (max-width: 900px) {
    font-size: 14px;
    line-height: 16px;
  }
`;

const StepDetails = styled.div<{ active?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 15px;
  max-width: 460px;
  @media (max-width: 900px) {
    position: absolute;
    background: #fff;
    z-index: 10;
    top: -550%;
    right: 0%;
    width: 40%;
    gap: 5px;
    ${({ active }) => !active && `display:none;`}
  }
`;
const StepHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  @media (max-width: 900px) {
    flex-direction: row;
    gap: 10px;
  }
`;
const StepDivider = styled.div<{ active?: boolean; last?: boolean }>`
  width: 4px;
  height: 100px;
  background: #00bbbc;
  border-radius: 4px;

  ${({ active }) => !active && `opacity: 0.3;`}
  @media (max-width: 900px) {
    width: 50px;
    height: 4px;
    ${({ last }) => last && `display: none;`}
  }
`;

export default function InvestComponent() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [isMouseOver, setIsMouseOver] = React.useState(false);

  const handleOnMouseEnter = () => {
    setIsMouseOver(true);
  };

  const handleOnMouseLeave = () => {
    setIsMouseOver(false);
  };

  function handleWheel(e: React.WheelEvent) {
    if (isMouseOver) {
      var nextImageIndex: number;

      // The following condition
      // finds the next image
      // index depending if we
      // scroll up or scroll down
      if (e.deltaY > 0) {
        nextImageIndex = (activeStep + 1) % 6;
      } else {
        nextImageIndex = (activeStep - 1 + 4) % 4;
      }
    }
  }

  return (
    <Wrapper
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      onWheel={(e) => handleWheel(e)}
    >
      <BackgroundImg src={bgImg} />
      <Title>How To Invest In Online Gold/Silver</Title>
      <Container>
        <Row>
          {activeStep === 0 && <Image src={investImg} />}
          {activeStep === 1 && <Image src={investImg2} />}
          {activeStep === 2 && <Image src={investImg3} />}
          {activeStep === 3 && <Image src={investImg4} />}
        </Row>
        <Row>
          <Steps>
            <Step>
              <StepHeader>
                <StepIndex active={true} onClick={() => setActiveStep(0)}>
                  1
                </StepIndex>
                <StepDivider active={true} />
              </StepHeader>
              <StepDetails active={true}>
                <StepTitle>Login</StepTitle>
                <StepDescription>
                  Download Mobile app and click on the Gold/Silver tab from the
                  dashboard.
                </StepDescription>
              </StepDetails>
            </Step>

            <Step>
              <StepHeader>
                <StepIndex
                  active={activeStep > 0}
                  onClick={() => setActiveStep(1)}
                >
                  2
                </StepIndex>
                <StepDivider active={activeStep > 0} />
              </StepHeader>
              <StepDetails active={activeStep > 0}>
                <StepTitle>Enter Amount</StepTitle>
                <StepDescription>
                  Enter the desired amount in rupees or grams of Gold/Silver to
                  purchase/sell.
                </StepDescription>
              </StepDetails>
            </Step>

            <Step>
              <StepHeader>
                <StepIndex
                  active={activeStep > 1}
                  onClick={() => setActiveStep(2)}
                >
                  3
                </StepIndex>
                <StepDivider active={activeStep > 1} />
              </StepHeader>
              <StepDetails active={activeStep > 1}>
                <StepTitle>Buy</StepTitle>
                <StepDescription>
                  Click on the "Buy Now" tab to purchase and make the payment
                  from your account.
                </StepDescription>
              </StepDetails>
            </Step>

            <Step>
              <StepHeader>
                <StepIndex
                  active={activeStep === 3}
                  onClick={() => setActiveStep(3)}
                >
                  4
                </StepIndex>
                <StepDivider last active={activeStep === 3} />
              </StepHeader>
              <StepDetails active={activeStep === 3}>
                <StepTitle>Gold Accumulated</StepTitle>
                <StepDescription>
                  The purchased gold/silver quantity/value will be updated in
                  your account (digital locker of your gold/silver holdings).
                </StepDescription>
              </StepDetails>
            </Step>
          </Steps>
        </Row>
      </Container>
    </Wrapper>
  );
}
