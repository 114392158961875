import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Navbar from "../components/Navbar";
import linkedInImg from "../assets/images/linkedin.png";
import twitterImg from "../assets/images/twitter.png";
import telegramImg from "../assets/images/telegram.png";
import Footer from "../components/Footer";
import bgImg from "../assets/images/background1.png";

import contactBarsImg from "../assets/images/contact-bars.png";
import ReCaptchaForm from "../components/ReCaptchaForm";

const Wrapper = styled.div`
  max-width: 100%;
  position: relative;
  z-index: 22;
  overflow: hidden;
  @media (max-width: 900px) {
    width: 100%;
    max-width: unset;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  @media (max-width: 900px) {
    flex-direction: column;
    padding: 0px 25px;
  }
`;
const Row = styled.div`
  display: flex;
  width: 45%;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 18px;
  position: relative;
  @media (max-width: 900px) {
    width: 100%;
    padding: 0px;
    gap: 20px;
    align-items: center;
  }
`;
const Title = styled.h1`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 91%;
  text-align: right;
  color: #27153e;
  max-width: 470px;
  @media (max-width: 900px) {
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    margin-bottom: 20px;
  }
`;
const Description = styled.p`
  font-family: "Inter", sans-serif;
  font-style: normal;
  max-width: 270px;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  text-align: right;
  text-transform: capitalize;
  color: #27153e;
  @media (max-width: 900px) {
    text-align: center;
  }
`;
const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  @media (max-width: 900px) {
    display: none;
  }
`;
const ContactItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 22px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  text-align: right;
  color: #27153e;
`;
const Link = styled.a`
  font-weight: 400;
  font-size: 14px;
  font-family: "Inter", sans-serif;
`;
const Socials = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
`;
const SocialItem = styled.img`
  width: 37px;
  height: 37px;
  cursor: pointer;
  z-index: 1;
`;
const BackgroundImg = styled.img`
  position: absolute;
  right: 0;
  top: -10%;
  object-fit: cover;
`;
const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 11;
  padding: 40px 45px;
  width: 450px;
  background: linear-gradient(0deg, #edfbfa, #edfbfa);
  border: 0.472076px solid #6a6a6a;
  box-shadow: 4.08944px 4.08944px 13.9041px rgba(0, 0, 0, 0.2);
  border-radius: 20.0383px;
  gap: 15px;
  @media (max-width: 900px) {
    padding: 35px 30px;
    max-width: 90%;
    margin-top: 25px;
  }
`;

const FormInput = styled.input`
  border: none;
  background: #fff;
  width: 100%;
  height: 60px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 7px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #6a6a6a;
  padding: 0px 10px;
  :focus-within {
    outline: none;
  }
  @media (max-width: 900px) {
    height: 40px;
    padding: 0px;
  }
`;

const InputItem = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 15px;
`;
const BckgImg = styled.img`
  position: absolute;
  top: 25%;
  left: 0%;
  width: 70%;
  object-fit: contain;
  @media (max-width: 1250px) {
    display: none;
  }
`;
const InputLabel = styled.p`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  color: #27153e;
`;

const ErrorMessage = styled.p`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #be0039;
`;

export default function ContactUs() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [address, setAddress] = useState("");
  const [errorIndex, setErrorIndex] = useState("");

 
  return (
    <Wrapper>
      <Navbar />
      <BackgroundImg src={bgImg} alt="background" />
      <Container>
        <Row>
          <BckgImg src={contactBarsImg} alt="background" />
          <Title>Soulmachine Innovations Pvt ltd</Title>
          <Description>
                Registered Address: <br/> 
                House No. S-6A,Third Floor, <br/> 
                Shri Niwashpuri Extension,<br/> 
                Delhi-110065<br/> 
                India
            {/* Soulmachine Innovations Pvt ltd <br /> Address: S-6A, THIRD FLOOR,
            SHRI NIWASPURI EXTENSION NEW DELHI New Delhi DL 110065 IN */}
          </Description>
          <ContactInfo>
            <ContactItem>
              Email us:
              <Link href="mailto:support@OroPocket.com">
                Support@OroPocket.com
              </Link>
            </ContactItem>

            <ContactItem>
              Contact us:
              <Link href="tel:+919315065097">
                +919315065097
                
              </Link>
            </ContactItem>
            

            <ContactItem>
              Website:
              <Link href="https://oropocket.com/" target="_blank">
                www.Oropocket.com
              </Link>
            </ContactItem>
          </ContactInfo>
          <Socials>
            <a
              href="https://www.linkedin.com/company/oropocket"
              target="_blank"
              rel="noreferrer noopener"
            >
              <SocialItem src={linkedInImg} />
            </a>

            <a
              href="https://twitter.com/oropocket"
              target="_blank"
              rel="noreferrer noopener"
            >
              <SocialItem src={twitterImg} />
            </a>
          </Socials>
        </Row>
        <Row>
          <Form>
            <InputItem>
              <InputLabel>Email Address</InputLabel>
              {errorIndex.includes("email") && (
                <ErrorMessage>Please enter a valid email address</ErrorMessage>
              )}
              <FormInput
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </InputItem>
            <InputItem>
              <InputLabel>Full Name</InputLabel>
              {errorIndex.includes("name") && (
                <ErrorMessage>Please enter a valid name</ErrorMessage>
              )}

              <FormInput
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </InputItem>
            <InputItem>
              <InputLabel>Address</InputLabel>
              {errorIndex.includes("address") && (
                <ErrorMessage>Please enter a valid address</ErrorMessage>
              )}
              <FormInput
                value={address}
                required
                onChange={(e) => setAddress(e.target.value)}
              />
            </InputItem>
            <InputItem>
              <InputLabel>Message</InputLabel>
              {errorIndex.includes("message") && (
                <ErrorMessage>Please enter a valid message</ErrorMessage>
              )}
              <FormInput
                value={message}
                required
                onChange={(e) => setMessage(e.target.value)}
              />
            </InputItem>
            <ReCaptchaForm
              name={name}
              email={email}
              message={message}
              address={address}
              error={errorIndex}
              setError={setErrorIndex}
            />
          </Form>
        </Row>
      </Container>
      <Footer />
    </Wrapper>
  );
}
