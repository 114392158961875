import React, { useEffect, useState } from "react";
import { InputAdornment } from "@material-ui/core";
import oro_event from "../../assets/images/oro_event.png";
import SearchIcon from "../../assets/images/searchIcon.png";
import EventBanner from "../../assets/images/eventBanner.png";
import Copy from "../../assets/images/Copy.png";
import Calender from "../../assets/images/calender.png";
import Location from "../../assets/images/location.png";
import Internet from "../../assets/images/internet.png";
import Banner from "../../assets/images/eventOro.png";

import {
  BackgroundContainer,
  EqualDiv,
  EventCard,
  EventCardContainer,
  EventContainer,
  EventTheme,
  EventTitle,
  FlexBetween,
  HeroSectionWrapper,
  SearchTextField,
} from "./events.style";
import EventCards from "./EventCard";
import Footer from "../Footer";
import Navbar from "../Navbar";
import EventModal from "./EventModal";
import ModalCard from "./ModalCard";
import EmptyEvent from "./EmptyEvent";

// const EventDetails = [
//   {
//     eventTitle:'Bharat Tour',
//     eventDate:'06 May 2023',
//     eventLocation:'Kolkata',
//     eventTag:['Webinar','Host']
//   },
//   {
//     eventTitle:'Bharat Tour',
//     eventDate:'06 May 2023',
//     eventLocation:'Kolkata',
//     eventTag:['Webinar','Host']
//   },
//   {
//     eventTitle:'Bharat Tour',
//     eventDate:'06 May 2023',
//     eventLocation:'Kolkata',
//     eventTag:['Webinar','Host']
//   },
// ]

type EventDetails = {
  id: number;
  eventTitle: string;
  eventDate: string;
  eventLocation: string;
  eventTag: string[];
  eventBanner: string;
  eventDes: string;
  eventRegLink: string;
}[];
const eventDetails: EventDetails = [
  {
    id: 1,
    eventTitle: "FiNext Conference",
    eventDate: "21 Jun 2023",
    eventLocation: "Le Méridien Dubai Hotel & Conference Centre",
    eventTag: ["Event"],
    eventBanner: Banner,
    eventDes:
      "FiNext Conference & Expo was initiated because the traditional finance industry needed a space where the smartest, inventive and ambitious people could come together, interact and build meaningful alliances.",
    eventRegLink: "https://finextcon.com/",
  },
];

const Event = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [eventModalData, setEventModaldata] = useState<any>([]);

  const handleModal = (id: number) => {
    const filterEventData = eventDetails.filter((event) => {
      if (event.id === id) {
        return event;
      }
    });
    console.log(filterEventData, "dataa");
    setEventModaldata(filterEventData);
    setModalOpen(true);
  };

  useEffect(() => {
    console.log(eventModalData, "data");
  }, [eventModalData]);
  return (
    <>
      <Navbar eventPage={true} />
      <BackgroundContainer style={{ backgroundColor: "#ffffff" }}>
        <EventContainer>
          <HeroSectionWrapper>
            <EqualDiv isJustifyStart={true}>
              <EventTheme>
                Diversify your portfolio with transparency and safety
              </EventTheme>
            </EqualDiv>
            <EqualDiv>
              <img src={oro_event} alt="event logo" style={{ width: "70%" }} />
            </EqualDiv>
          </HeroSectionWrapper>
        </EventContainer>
      </BackgroundContainer>
      <BackgroundContainer>
        <EventContainer>
          <FlexBetween>
            <EventTitle>Events</EventTitle>
            <SearchTextField
              placeholder="Search events"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={SearchIcon} width={24} height={24} />
                  </InputAdornment>
                ),
              }}
            />
          </FlexBetween>
          <EventCardContainer>
            {eventDetails.map((card, ind) => {
              return (
                <>
                  <EventCards
                    eventTitle={card.eventTitle}
                    eventDate={card.eventDate}
                    eventLocation={card.eventLocation}
                    eventTags={card.eventTag}
                    key={ind}
                    handleModal={() => {
                      handleModal(card.id);
                    }}
                  />
                </>
              );
            })}

            {/* <EmptyEvent /> */}
          </EventCardContainer>
        </EventContainer>
      </BackgroundContainer>

      <Footer />

      <EventModal
        open={isModalOpen}
        onClose={() => setModalOpen(false)}
        title="Event"
      >
        <ModalCard data={eventModalData} />
      </EventModal>
    </>
  );
};

export default Event;
