import React from "react";
import Calender from "../../assets/images/calender.png";
import Location from "../../assets/images/location.png";
import {
  CardHeader,
  EventCard,
  EventTag,
  Flexbox,
  HostTag,
  Image,
  InsideDiv,
  LearnMore,
  LogoContainer,
  SmallText,
  WebinarTag,
} from "./events.style";

interface CardProps {
  handleModal?: () => void;
  eventTitle: string;
  eventDate: string;
  eventLocation: string;
  eventTags: string[];
}

const EventCards = ({
  handleModal,
  eventDate,
  eventLocation,
  eventTags,
  eventTitle,
}: CardProps) => {
  return (
    <>
      <EventCard>
        <InsideDiv>
          <Flexbox>
            {/* <WebinarTag>Webinar</WebinarTag>
            <HostTag>Host</HostTag>
          <EventTag>Event Partner</EventTag> */}
            {eventTags.map((tag) => {
              return (
                <>
                  <EventTag>{tag}</EventTag>
                </>
              );
            })}
          </Flexbox>
          <a href="https://finextcon.com/" target="_blank" rel="noopener">
            <CardHeader>{eventTitle}</CardHeader>
          </a>
          <div style={{ display: "flex" }}>
            <LogoContainer>
              <Image src={Calender} />
            </LogoContainer>
            <SmallText>{eventDate}</SmallText>
          </div>
          <div style={{ display: "flex" }}>
            <LogoContainer>
              <Image src={Location} />
            </LogoContainer>
            <SmallText>{eventLocation}</SmallText>
          </div>

          <LearnMore onClick={handleModal}>Learn More</LearnMore>
        </InsideDiv>
      </EventCard>
    </>
  );
};

export default EventCards;
