import React from "react";

import styled from "styled-components";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import bgImg from "../assets/images/background1.png";
import indianFLagImg from "../assets/images/indianFLag.png";
const Wrapper = styled.div`
  max-width: 100%;
  position: relative;
  z-index: 22;
  overflow: hidden;
`;
const Container = styled.div`
  padding: 0px 50px;
  position: relative;
  @media (max-width: 900px) {
    padding: 0px 25px;
  }
`;

const BackgroundImg = styled.img`
  position: absolute;
  right: 0;
  top: -20%;
  object-fit: cover;
`;

const Title = styled.h1`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 50px;
  line-height: 120%;
  color: #00bead;
  text-align: center;
`;

const Subtitle = styled.h2`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 120%;
  /* or 43px */

  text-align: center;
  margin-top: 32px;
  color: #27153e;
`;
const InputItem = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 15px;
`;

const InputLabel = styled.p`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 19.1493px;
  line-height: 120%;
  /* or 23px */
  color: #27153e;
`;
const FormInput = styled.input`
  border: none;
  border-bottom: 1px solid #00bead;
  width: 100%;
  background-color: transparent;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 19.1493px;
  line-height: 120%;
  padding: 0px;
  :focus {
    outline: none;
  }
`;
const Form = styled.form`
  margin: 0 auto;
  max-width: 540px;
  margin-top: 45px;
`;
const Select = styled.select`
  border: none;
  border-bottom: 1px solid #00bead;
  width: 100%;
  background-color: transparent;
  font-family: "Inter", sans-serif;
  :focus {
    outline: none;
  }
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
`;
const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
`;

const LoginButton = styled.button`
  border: 2px solid #00bead;
  background-color: transparent;
  filter: drop-shadow(-2px 0px 24px rgba(0, 190, 173, 0.37));
  border-radius: 12px;
  padding: 15px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  width: 205px;
  height: 49px;
  color: #00bead;
  margin-top: 25px;
  cursor: pointer;
`;
const CopyRight = styled.div`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  height: 65px;
  width: 100%;
  background: #01bbb7;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const SignUpButton = styled.button`
  border: 2px solid #00bead;
  background-color: #00bead;
  filter: drop-shadow(-2px 0px 24px rgba(0, 190, 173, 0.37));
  border-radius: 12px;
  border-bottom-right-radius: 0px;
  padding: 15px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  width: 165px;
  height: 49px;
  color: #fff;
  margin-top: 25px;
  margin-bottom: 50px;
  cursor: pointer;
`;
function Login() {
  return (
    <Wrapper>
      <BackgroundImg src={bgImg} alt="background" />

      <Navbar />

      <Container>
        <Title>Glad To See You Again!</Title>
        <Subtitle>Please Sign In</Subtitle>
        <Form>
          <InputItem>
            <InputLabel>Country</InputLabel>
            <Select name="select">
              <option value="">Select Country</option>
              <option value="India">
                <Icon src={indianFLagImg} /> India
              </option>
              <option value="UK">UK</option>
            </Select>
          </InputItem>
          <InputItem>
            <InputLabel>Email Address/Mobile Number</InputLabel>
            <FormInput type="email" placeholder=" Enter email/phone number " />
          </InputItem>
        </Form>
        <Buttons>
          <LoginButton>Login Via Password</LoginButton>
          <LoginButton>Login Via OTP</LoginButton>
        </Buttons>
        <Buttons>
          <SignUpButton>Sign Up</SignUpButton>
        </Buttons>
      </Container>
      <Footer />
      <CopyRight>Copyright ©2022, OroPocket</CopyRight>
    </Wrapper>
  );
}

export default Login;
