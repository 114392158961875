import React from "react";
import styled from "styled-components";
import Navbar from "../components/Navbar";
import bgImg from "../assets/images/background1.png";
import bgImg2 from "../assets/images/background3.png";

import Footer from "../components/Footer";

const Wrapper = styled.div`
  max-width: 100%;
  position: relative;
  z-index: 22;
  overflow: hidden;
`;
const Title = styled.h1`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 61px;
  background: linear-gradient(267.19deg, #00bcb3 5.03%, #01bbb7 5.03%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-top: 20px;
  @media (max-width: 900px) {
    font-size: 30px;
    line-height: 36px;
    text-align: center;
  }
`;
const BackgroundImg = styled.img`
  position: absolute;
  right: 0;
  top: 0;
  object-fit: cover;
`;
const BackgroundImg2 = styled.img`
  position: absolute;
  left: 0%;
  bottom: 34%;
  object-fit: cover;
  opacity: 0.4;
  z-index: 1;
`;
const Container = styled.div`
  padding: 0px 50px;
  position: relative;
  @media (max-width: 900px) {
    padding: 0px 25px;
  }
`;
const P = styled.p`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-top: 20px;
  color: #27153e;
  z-index: 5;
  @media (max-width: 900px) {
    text-align: center;
  }
`;
const B = styled.p`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  margin-top: 40px;
  color: #27153e;
  z-index: 5;
  @media (max-width: 900px) {
    text-align: center;
  }
`;
const Subtitle = styled.h2`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  margin-top: 100px;
  color: #27153e;
  @media (max-width: 900px) {
    font-size: 26px;
    line-height: 36px;
    text-align: center;
    margin-top: 50px;
  }
`;

export default function Terms() {
  return (
    <Wrapper>
      <Navbar />
      <BackgroundImg src={bgImg} alt="background" />
      <BackgroundImg2 src={bgImg2} alt="background" />

      <Container>
        <Title>Terms & Conditions </Title>

        <B>
          OroPocket Account Terms & Conditions
          <br />
          <br />
        </B>
        <P>
          Please read this Agreement carefully. It sets out the terms of a
          legally binding agreement between you and OroPocket.
          <br />
          <br />
          This Agreement sets out the terms and conditions upon which you
          contract with OroPocket and which govern your relationship with
          OroPocket. By signing up as a OroPocket client, you are agreeing to
          the terms and conditions of this Agreement. The English language
          version of the Agreement is the only legally binding version, with any
          translations in other languages being provided merely for convenience
          purposes.
          <br />
          <br />
          If you do not agree to the terms of this Agreement, you should not
          sign up to become a client of OroPocket. You should not enter into
          this Agreement unless you understand the nature of this business and
          the extent of your exposure to risk. The value of Gold can rise or
          fall when measured against exchange currencies and price movements of
          exchange currencies may change quickly. You assume any exchange rate
          risk. If you have any questions, please contact us.
          <br />
          <br />
          This Agreement may change from time to time. We recommend that you
          revisit this page periodically and print and save the latest copy of
          this Agreement for your records. We strongly recommend that you read
          these terms and conditions in their entirety.
          <br />
          <br />
          You should refer to Clause 21 in the OroPocket Terms of Use to see the
          ways in which we may amend these Terms.
        </P>

        <B>Don’t know what something means?</B>
        <P>
          This Agreement uses a number of special words with a particular
          meaning. You will find a glossary of terms at clause 1 of
          <br />
          this Agreement to help you understand what these words mean.
          <br />
          <br />
          If you do not understand any aspect, you can contact us for further
          information in the following ways:
        </P>
        <B>DEFINITIONS Interpretation</B>
        <P>
          In this Agreement, unless otherwise expressly stated or the content
          requires otherwise, the following terms shall have the meanings set
          out below:
          <br />
          <br />
          <b>“Account”</b>
          means the electronic record kept by OroPocket of:
        </P>
        <P>
          (a) the quantity of Gold expressed in grams, kilograms or troy ounces
          of gold held by or for you
          <br />
          or on your behalf at the Vault;
          <br />
          <br />
          (b) the quantity of Reserved Bars held by or for you or on your behalf
          at the Vault, which quantity
          <br />
          is expressed in whole bars together with corresponding bar serial
          numbers;
          <br />
          <br />
          (c) any money held by us on your behalf;
          <br />
          <br />
          (d) your transactions with us;
          <br />
          <br />
          (e) our fees for the services provided to you;
          <br />
          <br />
          (f) any other account held by you in connection with the OroPocket
          Services.
        </P>
        <P>
          <b> “Agreement” </b> means these terms and conditions, any
          account-opening documentation (including an online registration form
          you complete when applying for an Account) and any other documents,
          terms and conditions, or policies which are expressly stated by
          OroPocket to form part of the agreement between you and us.
          <br />
          <br />
          <b> “Chain of Integrity Standard” </b> means the minimum standard
          which Gold must meet before it will be accepted by us, which is
          currently:
          <br />
          <br />
          (a) in the case of large gold bars, the Good Delivery standards and
          refiner standards as prescribed by World Gold Council. (WGC)
          <br />
          <br />
          <b> “Funds” </b> means cash in your Account which is due from a) a
          sale of Gold that has settled; or b) the crediting of funds to your
          Account by debit card, direct debit; or bank transfers; and which has
          cleared the banking process.
          <br />
          <br />
          <b> “Client”, “User”, “you”</b> means a person who has registered and
          has been accepted by us for the OroPocket Services.
          <br />
          <br />
          <b>“Constructive possession” </b>
          is a general principle that means a person who has possession of
          property is to be presumed to be the owner of it. However, the law
          recognises in some cases that physical delivery would be impractical
          due to the nature or location of the object. “Constructive possession”
          occurs when a person who does not physically hold property is yet
          deemed to be in legal possession of it. By purchasing gold on the
          OroPocket Platform, you acquire legal possession of your gold held in
          custody by the vault.
          <br />
          <br />
          <b>“Gold Account” </b>
          means your Account for gold (other than in Reserved Bars).
          <br />
          <br />
          <b>“OroPocket”, “we”, “us” or “our” </b>
          means Soul. Machine Ltd, a private limited company incorporated in
          England.
          <br />
          <br />
          <b>“OroPocket Platform” </b>
          means the OroPocket Website, the OroPocket Application, Application
          Programming Interface (“API”)[, and any associated OroPocket systems
          or mobile applications]
          <b> (together, the “OroPocket Platform”).</b>
          <br />
          <br />
          <b>“OroPocket Services” </b>
          means the OroPocket Platform and any of the services provided to you
          by OroPocket, together with the OroPocket Platform.
          <br />
          <br />
          <b>“OroPocket Website” </b>
          means the public and private website accessible at www.oropocket.com
          as amended from time to time.
          <br />
          <br />
          <b>“Gold” </b>
          means physical gold that meets the WGC Standards. OroPocket records
          the Gold held by or for you or on your behalf in grams, kilograms or
          troy ounces.
          <br />
          <br />
          <b> “Gold Transfer” </b>
          or
          <b> “Gold Peer-to-Peer” </b>
          means the transfer of Gold in accordance with clause 4.4 of this
          Agreement from one User, on their instruction, to another User or
          OroPocket that is recorded by debiting the corresponding amount of
          Gold from the transferor’s Account and crediting the same to the
          transferee’s Account;
          <br />
          <br />
          <b>“Reserved Bar” </b>
          means a whole bar of Gold, and not a fraction of a bar of Gold, which
          is recorded in the Account as your exclusive property and in which no
          other person has any share.
          <br />
          <br />
          <b> “Gold Savings Account” </b>
          means your Account for Gold in Reserved Bars only.
          <br />
          <br />
          <b>“Security Information” </b>
          means a user identification name, code, personal identification
          number, password or such other security information as we may
          determine for use in connection with the OroPocket Services.
          <br />
          <br />
          <b> “Vault” </b>
          means the service provider appointed by us in accordance with Clause
          2.0 to store Gold for or on behalf of all Clients.
          <br />
          <br />
          OPERATING PROVISIONS
          <b> 1.0 Your Gold Accounts</b>
          <br />
          <br />
          1.1 All Gold Accounts (current or savings accounts) operated by
          OroPocket are allocated Gold accounts. This means that the Gold is
          stored under a safekeeping or custody arrangement at the Vault by or
          for you or on your behalf. The Vault does not retain a proprietary
          interest in the Gold.
          <br />
          <br />
          1.2 For your Gold Current Account:
          <br />
          <br />
          (a) each unit on your Account represents a gram or ounce (or a part
          thereof) of the Gold constituting varieties of sizes of bars held in a
          designated precious metals storage facility at a Vault;
          <br />
          <br />
          (b) units recorded in your Account represent and are evidence of your
          proportionate share in the Gold. The number of units represented by
          the credit balance in your Account quantifies the total of all your
          interests in the Gold at the relevant the Vault;
          <br />
          <br />
          (c) the number of units held by all Clients which is recorded in the
          Accounts always represents an identical amount of Gold held in the
          Vaults;
          <br />
          <br />
          (d) the Gold held in each Vault is owned in common in proportion to
          the credit balance of relevant units as recorded on the relevant
          Account by all the relevant Clients.
          <br />
          <br />
          1.3 You can obtain units where your Account allows you to make or
          receive Gold Transfers, receiving units from another User. When you
          obtain units, you acquire or increase your undivided interest in the
          relevant Gold (in your Gold Current Account) by an amount equal to the
          value of the money paid for the purchase.
          <br />
          <br />
          1.4 Any credits that you obtain through a Gold Transfer represent the
          transfer of a proportionate share of the relevant Gold from the
          transferor to your Account whether or not such transfer is made in
          relation to the sale or purchase of any goods or services or
          otherwise.
          <br />
          <br />
          1.5 Reserved Bar(s) in your Gold Savings Account will be reserved
          exclusively to you as your property. Your ownership of the Reserved
          Bar will be evidenced by the record in your Account which will record
          the quantity of Reserved Bars held by you or for you or on your behalf
          at the Vault, which quantity is expressed in whole bars together with
          corresponding serial numbers. No fraction of a bar may be held as a
          Reserved Bar.
          <br />
          <br />
          <b>2.0 Storage and Insurance</b> 2.1 You authorise us to arrange for
          the storage, transportation, and insurance of your Gold. This
          authority shall be deemed to include an authority to enter into
          contracts on your behalf as your agent in respect of the storage,
          transportation, or insurance of your Gold but shall not be construed
          as an obligation to enter into any such contracts.
          <br />
          <br />
          2.2 Subject to Clause 3.0 (Reserved Bars), title to the Gold in
          allocated storage at the Vault shall at all times vest in all owners
          in common of that Gold. If and to the extent that OroPocket has a
          residual interest in the Gold so held, including any rights against
          third parties, this will be held on behalf of the owners in common.
          OroPocket arranges storage of all such Gold for and on behalf of all
          owners in common (for these purposes, in respect of your share, we act
          as your agent).
          <br />
          <br />
          2.3 OroPocket only has a proprietary interest in Gold in storage to
          the extent that it is entitled to deduct from the proceeds of sale
          payment for fees owing to us and/or the Vault.
          <br />
          <br />
          2.4 You agree to keep your Gold (and any interest in it) acquired and
          held under and in accordance with this Agreement free from any liens,
          encumbrances, charges, or claims.
          <br />
          <br />
          2.5 Each Vault is obliged to maintain insurance at least equal to one
          hundred per cent. (100%) of the value of Gold held for OroPocket and
          all its Clients.
          <br />
          <br />
          <b>3.0 Reserved Bars</b> This clause applies only where you have a
          Gold Savings Account with us.
          <br />
          <br />
          3.1 You may elect to hold individual Reserved Bar(s) reserved
          exclusively to you as your property provided that you hold within one
          Vault sufficient Gold that matches or exceeds the smallest available
          bar (which meets the Chain of Integrity Standard) in that Vault. All
          Reserved Bars that you own will be segregated from other Gold held in
          the relevant Vault.
          <br />
          <br />
          3.2 A Reserved Bar may not be sold while it is reserved to you until
          it has been released by you in accordance with your instructions.
          Where you hold Gold in Reserved Bars only, by giving an instruction to
          OroPocket to make a payment or a Gold Transfer, you authorise
          OroPocket to de-register as many Reserved Bars in your Account as is
          required to make the payment and release them.
          <br />
          <br />
          3.3 Any tax or duty liabilities or obligations attaching to a Reserved
          Bar held in your name will be yours, as Client and owner of the
          Reserved Bar. OroPocket shall bear no responsibility for any tax or
          duty payable in respect of the Reserved Bar.
          <br />
          <br />
          <b> 4.0 Sale, purchase and transfer of Gold</b> 4.1 You may purchase
          or sell Gold through the OroPocket Platform in accordance with this
          Agreement.
          <br />
          <br />
          4.2 We may transact in Gold acting as principal or as agent following
          receipt of your order. We shall transfer ownership of the Gold so
          obtained to you. Upon depositing the Gold purchased with a Vault,
          delivery shall occur by way of “constructive possession” which means
          that you will have ownership of the Gold stored in the Vault.
          <br />
          <br />
          4.3 You agree that OroPocket shall have full authority as your agent
          and in your name to buy, sell, exchange, redeem, hold, convert or
          otherwise deal with Gold for your Account.
          <br />
          <br />
          4.4 If you instruct OroPocket to execute a Gold Transfer (Gold
          Peer-to-Peer), you authorise OroPocket to transfer a proportionate
          share of Gold held by you into another Account you have or to another
          User nominated by you in the instruction. All such Gold Transfers
          shall be effected by debiting your Account by the amount representing
          the quantity of Gold transferred by you and crediting the Account
          nominated by you in the instruction with the corresponding amount.
          Gold Transfers cannot be made by transferring Reserved Bars.
          <br />
          <br />
          4.5 When we accept your order, we will use all reasonable endeavours
          to carry it out. However, we will not be liable to you for any loss or
          expense you suffer if we are unable to carry out an order for whatever
          reason (other than as a result of our negligence, fraud or wilful
          default) or due to a breakdown or failure of transmission or
          communication systems or where there is a delay or change in Gold
          market conditions before the transaction is completed.
          <br />
          <br />
          4.6 No orders can be placed until an Account has been opened and Funds
          received. By placing an order for the purchase of Gold, you agree that
          you will have sufficient Funds in your Account required to make the
          payment including fees to settle the trade.
          <br />
          <br />
          4.7 You agree that the price for any purchase or sale of Gold for any
          transaction within your Gold Accounts will be based on real-time
          market prices, available in the OroPocket app.
          <br />
          <br />
          4.8 You authorise OroPocket to act upon instructions given securely
          via your Account. You agree that we are entitled to accept all
          instructions received by OroPocket without any further enquiry or
          verification provided that they are, or appear to be, given using your
          Security Information.
          <br />
          <br />
          <b>5.0 Fees and charges </b>
          5.1 We will charge fees in respect of the services provided to you
          under this Agreement as specified in our Fee section (as amended from
          time to time) which is available on the OroPocket Website.
          <br />
          <br />
          5.2 Unless otherwise agreed by us in writing, all amounts (including,
          without limitation, all fees and charges) payable by you shall be due
          on demand without set-off, counterclaim or deduction.
          <br />
          <br />
          5.3 All payments due from you to us under this Agreement shall be made
          by you in funds unless otherwise agreed by us in writing.
          <br />
          <br />
          5.4 We reserve the right to collect fees due under this Agreement in
          Gold (other than Reserved Bars) or the national currency of any
          territory which is accepted by us in exchange for Gold. You hereby
          authorise us to deduct from such Gold or currency in your Account any
          amounts in respect of fees or charges that you may incur by using the
          services and all other fees, charges, costs and expenses that we may
          incur in connection with the services we provide to you or pursuant to
          our relationship with you.
          <br />
          <br />
          5.5 You agree that we may, at our sole discretion and without notice
          to you, appropriate the whole or any part of the Gold or Funds in your
          Account or, in the case of Gold, sell or convert the same into money
          and use the Gold so appropriated or the proceeds of that sale or
          conversion to set-off any of your liabilities or obligations to us
          whether in respect of fees and charges or any other monies due to us.
          <br />
          <br />
          <b> Set-off </b>
          OroPocket may, without limiting its other rights or remedies, set-off
          any amount owing to it (or to any of its affiliates) by the Client
          against any amount payable by OroPocket (or its affiliates) to the
          Client.
        </P>
      </Container>
      <Footer />
    </Wrapper>
  );
}
