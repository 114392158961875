import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Footer from "../components/Footer";
import GrowingComponent from "../components/Growing";
import Home from "../components/Home";
import InvestComponent from "../components/InvestComponent";
import MobileAppComponent from "../components/MobileApp";
import PopUp from "../components/Modal/Banner";
import News from "../components/News";
import Simplified from "../components/Simplified";
import TableComponent from "../components/TableComponent";
import WhySection from "../components/WhySection";
import axios from "axios";

const Wrapper = styled.div`
  @media (max-width: 900px) {
    max-width: 100%;
    overflow: hidden;
  }
`;
const CopyRight = styled.div`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  height: 65px;
  width: 100%;
  background: #01bbb7;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1100;
  top: 0px;
  left: auto;
  right: 0px;
  @media (max-width: 468px) {
    padding: 10px;
    text-align: center;
    width: auto;
    height: auto;
  }
`;

export default function Landing() {
  const [helloBarMessage, setHelloBarMessage] = useState();
  const [redirectLink, setRedirectLink] = useState();
  const [APIFailed, setAPIFailed] = useState(false);
  const [token, setToken] = useState();

  const getMessage = () => {
    // console.log(token);
    try {
      fetch("https://my.oropocket.com/api/gethellobar.php")
        .then((res) => res.json())
        .then((res) => {
          setHelloBarMessage(res.status_message.message);
          setRedirectLink(res.status_message.button);
          setAPIFailed(false);
        })
        .catch((err) => {
          setAPIFailed(true);
          // console.log(err, "errors");
        });
    } catch (err) {
      console.log(err, "erros");
    }

    // try {
    //   await fetch("https://my.oropocket.com/restAPI/hello_bar.php", {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: "Bearer " + token.token,
    //     },
    //     body: JSON.stringify({
    //       secret_key: "6LdK8_wUAAAAADJpnz5hAbMFo02RASh_sBxaoDIs",
    //       api_id: "92878ff5ac678faa2c997ad3ad35c147",
    //       request_code: "778fa22123d35c146",
    //     }),
    //   })
    //     .then((res) => res.json())
    //     .then((res) => console.log(res));
    // } catch (err) {
    //   console.log(err);
    // }
  };

  useEffect(() => {
    getMessage();
    console.log(helloBarMessage);
  }, []);

  return (
    <Wrapper>
      {APIFailed ? null : (
        <a href={redirectLink}>
          <CopyRight>
            <h3>{helloBarMessage}</h3>
          </CopyRight>
        </a>
      )}
      {/* <PopUp/> */}
      {/* <Footer /> */}
      <Home />
      <News />
      <Simplified />
      <WhySection />
      <TableComponent />
      <InvestComponent />
      <GrowingComponent />
      <MobileAppComponent />
      <Footer />
    </Wrapper>
  );
}
