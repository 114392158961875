import React from "react";
import styled from "styled-components";
import growManImg from "../../assets/images/businessman.png";
const Wrapper = styled.div`
  max-width: 100%;
  position: relative;
  padding: 50px;
  z-index: 1;
  @media (max-width: 900px) {
    padding: 25px;
  }
`;
const Title = styled.h1`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 61px;
  background: linear-gradient(267.19deg, #00bcb3 5.03%, #01bbb7 5.03%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  @media (max-width: 900px) {
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    margin-bottom: 20px;
  }
`;
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 900px) {
    flex-direction: column;
    width: 100%;
  }
`;
const Row = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 50%;
  @media (min-width: 1600px) {
    width: unset;
  }
  gap: 35px;
  @media (max-width: 900px) {
    width: 100%;
    justify-content: center;
    gap: 10px;
    align-items: flex-end;
  }
`;
const Image = styled.img`
  z-index: 5;
  margin-left: 10%;
  max-width: 80%;
  object-fit: contain;
  @media (max-width: 900px) {
    max-width: 40%;
  }
`;
const Description = styled.p`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #27153e;
  @media (min-width: 1400px) {
    max-width: 600px;
  }
  @media (max-width: 900px) {
    text-align: center;
    margin-bottom: 25px;
  }
`;
const Button = styled.a`
  width: 150px;
  height: 49px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: #00bead;
  box-shadow: -2px 0px 24px rgba(0, 190, 173, 0.37);
  border-radius: 12px 12px 0px 12px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  cursor: pointer;
  @media (max-width: 900px) {
    width: 125px;
    height: 45px;
    font-size: 16px;
    margin: 0 auto;
  }
`;

export default function GrowingComponent() {
  return (
    <Wrapper>
      <Container>
        <Row>
          <Title>Start Growing With OroPocket</Title>
          <Description>
            OroPocket gives you direct ownership of vaulted Gold and Silver. Get
            24/7 access to your assets, best-in-class user experience, and peace
            of mind that comes through dealing with industry leaders.
          </Description>
          <Button href="https://my.oropocket.com/signup.php">
            Open Account
          </Button>
        </Row>
        <Row>
          <Image src={growManImg} />
        </Row>
      </Container>
    </Wrapper>
  );
}
