import React from "react";
import styled from "styled-components";
import moneyImg from "../../assets/images/moneyControl.png";
import mintImg from "../../assets/images/mint.png";
import financialImg from "../../assets/images/financial.png";
import bwImg from "../../assets/images/bw.png";
import storyImg from "../../assets/images/yourStory.png";
import newsImg from "../../assets/images/news.png";
import incImg from "../../assets/images/inc.png";
import thebetterImg from "../../assets/images/thebetter.png";

const Wrapper = styled.div`
  max-width: 100%;
  position: relative;
  padding: 50px;
`;
const Title = styled.h1`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 61px;
  text-align: center;
  background: linear-gradient(267.19deg, #00bcb3 5.03%, #01bbb7 5.03%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  @media (max-width: 900px) {
    font-size: 30px;
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 75px 0px;
  @media (max-width: 900px) {
    overflow: scroll;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;
const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 900px) {
    width: auto;
    gap: 15px;
    padding: 0px 15px;
  }
`;
const Image = styled.img`
  width: 21%;
  height: 140px;
  object-fit: contain;
  cursor: pointer;
  @media (max-width: 900px) {
    width: 200px;
  }
`;

export default function News() {
  return (
    <Wrapper>
      <Title>In The News</Title>
      <Container>
        <Row>
          <Image
            // onClick={() =>
            //   window.open(
            //     "https://hindi.moneycontrol.com/news/markets/oropocket-is-offering-cryptos-backed-by-gold-and-silver-274876.html"
            //   )
            // }
            style={{cursor: "not-allowed"}}
            src={moneyImg}
            alt="moneyControl"
          />

          <Image
            onClick={() =>
              window.open(
                "https://www.livemint.com/companies/news/oropocket-starts-dual-vault-system-for-physical-delivery-of-digital-gold-silver-11649754752100.html"
              )
            }
            src={mintImg}
            alt="mint"
          />
          <Image
            onClick={() =>
              window.open(
                "https://www.financialexpress.com/money/celebrating-with-cryptocurrency-decoding-the-crypto-craze-thats-sweeping-the-country/2360082/"
              )
            }
            src={financialImg}
            alt="financial"
          />
          <Image
            onClick={() =>
              window.open(
                "https://bwdisrupt.businessworld.in/article/OroPocket-Introduces-Auto-Invest-Plan-AIP-with-Tokenized-Gold-And-Silver/10-09-2021-404124/"
              )
            }
            src={bwImg}
            alt="bw"
          />
        </Row>
        <Row>
          <Image
            onClick={() =>
              window.open(
                "https://yourstory.com/the-decrypting-story/blockchain-startup-oropocket-digital-gold-silver/amp"
              )
            }
            src={storyImg}
            alt="story"
          />
          <Image
            // onClick={() =>
            //   window.open(
            //     "https://hindi.news18.com/news/business/not-getting-good-interest-on-fd-so-invest-in-gold-and-silver-crypto-here-is-your-chance-kpnd-3791579.html"
            //   )
            // }
            style={{cursor: "not-allowed"}}
            src={newsImg}
            alt="news"
          />
          <Image
            onClick={() =>
              window.open(
                "https://www.dqindia.com/this-deeptech-startup-helps-new-gen-diversify-assets-all-on-blockchain/"
              )
            }
            src={incImg}
            alt="inc"
          />
          <Image
            onClick={() =>
              window.open(
                "https://www.thebetterindia.com/260362/delhi-startup-oropocket-invest-digital-gold-silver/"
              )
            }
            src={thebetterImg}
            alt="thebetter"
          />
        </Row>
      </Container>
    </Wrapper>
  );
}
