import React, { useEffect } from "react";
import Landing from "./pages/Landing";
import styled from "styled-components";
import { Routes, Route, useLocation } from "react-router-dom";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import RefundPolicy from "./pages/RefundPolicy";
import ContactUs from "./pages/ContactUs";
import Redrassal from "./pages/Redressal";
import Terms from "./pages/Terms";
import About from "./pages/About";
import Login from "./pages/Login";
import { Grievence } from "./pages/Grievence";
import { Faq } from "./pages/Faq";
import DappsEvent from "./pages/DappsEvent";
const Wrapper = styled.div`
  background-color: #fdfeff;
`;

function ScrollToTop(props: any) {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return <>{props.children}</>;
}

function App() {
  return (
    <Wrapper>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/login" element={<Login />} />
          <Route path="/Privacy_Policy" element={<PrivacyPolicy />} />
          <Route path="/Refund_Policy" element={<RefundPolicy />} />
          <Route path="/Contact" element={<ContactUs />} />
          <Route path="/ticket" element={<Redrassal />} />
          <Route path="/Terms_and_Conditions" element={<Terms />} />
          <Route path="/about_us" element={<About />} />
          <Route path="/grievence" element={<Grievence />} />
          <Route path="/Faq" element={<Faq />} />
          <Route path="/events" element={<DappsEvent />} />
        </Routes>
      </ScrollToTop>
    </Wrapper>
  );
}

export default App;
