import React from "react";
import styled from "styled-components";
import logo from "../../assets/images/Logo.png";
import hamburgerIcon from "../../assets/images/hamburger.svg";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { Link, useLocation, useNavigate } from "react-router-dom";

interface NavbarPaddingProps {
  isEventPage?: boolean;
}
const Wrapper = styled.div<NavbarPaddingProps>`
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${(props) => (props.isEventPage ? "1rem 2rem" : "70px 55px")};
  z-index: 99;
  position: relative;
  @media (max-width: 900px) {
    padding: 28px;
  }
`;
const LogoImg = styled.img`
  object-fit: contain;
  @media (max-width: 900px) {
    height: 32px;
  }
`;
const NavItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  @media (max-width: 900px) {
    display: none;
  }
`;
const NavItem = styled.div<{ mobile?: boolean }>`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #27153e;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  gap: 15px;
  ${({ mobile }) =>
    mobile &&
    `
  font-family: 'Inter', sans-serif;
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 24px;
color: #27153E;
text-decoration: none;

  `}
  ::before {
    content: "";
    display: block;
    width: 4px;
    height: 4px;
    background-color: #27153e;
    border-radius: 50%;
    ${({ mobile }) => (mobile ? "display: none;" : "")}
  }
`;
const Button = styled.button`
  background: #00bead;
  box-shadow: -2px 0px 24px rgba(0, 190, 173, 0.37);
  border-radius: 12px 12px 0px 12px;
  width: 150px;
  height: 49px;
  border: none;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  cursor: pointer;
  @media (max-width: 900px) {
    height: 45px;
    width: 110px;
    font-size: 16px;
  }
`;
const MobileNav = styled.div`
  display: none;
  @media (max-width: 900px) {
    display: flex;
    align-items: center;
    gap: 12px;
  }
`;
const Hamburger = styled.img`
  width: 28px;
  height: 28px;
  cursor: pointer;
  object-fit: contain;
  display: none;
  @media (max-width: 900px) {
    display: block;
    z-index: 5;
  }
`;
const MobileMenu = styled.div<{ open: boolean }>`
  display: none;
  transition: 0.5s;
  transition: 0.5s ease-in-out;
  width: 100%;
  position: fixed;
  background: #defaf3;
  align-items: center;
  flex-direction: column;
  align-items: center;
  left: 0;
  top: -1990px;
  padding: 25px 0px;
  gap: 28px;
  z-index: 999;

  ${({ open }) => (open === true ? "top: 0px;" : "top: -110%;")}
  @media (max-width: 900px) {
    display: flex;
  }
`;
const CloseBtn = styled.button`
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #27153e;
`;
const A = styled.a`
  text-decoration: none;
`;

interface Navbarprops {
  eventPage?: boolean;
}
export default function Navbar({ eventPage }: Navbarprops) {
  const [mobileMenu, setMobileMenu] = React.useState(false);

  console.log(mobileMenu);
  return (
    <Wrapper isEventPage={eventPage}>
      <Link to="/">
        <LogoImg src={logo} alt="logo" />
      </Link>
      <NavItems>
        <A href="/#features">
          <NavItem>Features</NavItem>
        </A>

        <A href="https://blog.oropocket.com/" target="_blank">
          <NavItem>Blog</NavItem>
        </A>
        <A href="https://my.oropocket.com/login.php">
          <Button>Go to App</Button>
        </A>
      </NavItems>
      <MobileNav>
        <Hamburger
          src={hamburgerIcon}
          alt="hamburger"
          onClick={() => setMobileMenu(true)}
        />
        <Button
          onClick={() => window.open("https://my.oropocket.com/login.php")}
        >
          Go to App
        </Button>
      </MobileNav>
      <MobileMenu open={mobileMenu === true}>
        <Link to="/">
          <LogoImg src={logo} alt="logo" />
        </Link>
        <Link to="/Contact">
          <NavItem mobile>Contact Us</NavItem>
        </Link>

        <A href="/#features">
          <NavItem mobile>Features</NavItem>
        </A>
        <A href="https://blog.oropocket.com/" target="_blank">
          <NavItem mobile>Blog</NavItem>
        </A>
        <Link to="/Privacy_Policy">
          <NavItem mobile>Our Policy</NavItem>
        </Link>
        <CloseBtn onClick={() => setMobileMenu(false)}>
          Close
          <CloseRoundedIcon sx={{ fontSize: "16px" }} />
        </CloseBtn>
      </MobileMenu>
    </Wrapper>
  );
}
