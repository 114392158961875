import React from "react";
import Navbar from "../Navbar";
import styled from "styled-components";
import bgImg from "../../assets/images/background1.png";
import goldImg from "../../assets/images/gold.png";
import BuySection from "./BuySection";
import googlePlayImg from "../../assets/images/GooglePlay.png";
import appleImg from "../../assets/images/AppStore.png";

const Wrapper = styled.div`
  max-width: 100%;
  position: relative;
  // margin-top: 30px;
  @media (max-width: 468px) {
    // margin-top: 80px;
  }
`;
const BackgroundImg = styled.img`
  position: absolute;
  right: 0;
  top: 0;
  object-fit: cover;
`;
const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: baseline;
  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }
`;
const Column = styled.div`
  max-width: 50%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 50px;
  gap: 35px;
  @media (max-width: 900px) {
    width: 80%;
    max-width: 100%;
    padding: 28px;
    align-items: center;
  }
`;
const Text = styled.h4`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #27153e;
  display: flex;
  align-items: center;
  gap: 23px;
`;
const TextImage = styled.img`
  height: 42px;
  object-fit: contain;
`;
const Title = styled.h1`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 60px;
  line-height: 73px;
  color: #309b9b;
  position: relative;
  @media (min-width: 1600px) {
    max-width: 75%;
  }
  @media (max-width: 900px) {
    font-size: 30px;
    line-height: 36px;
    text-align: center;
  }
  position: relative;
  z-index: 5;
`;
const Underline = styled.span`
  ::after {
    content: "";
    display: block;
    position: absolute;
    background: linear-gradient(
      91.09deg,
      #7af1b6 0.92%,
      #86e780 46.8%,
      #eaee76 99.83%
    );
    box-shadow: 5px 4px 44px rgba(161, 231, 128, 0.62);
    height: 8px;
    width: 60%;
    bottom: 10px;
    right: 10%;
    z-index: -1;
    @media (min-width: 2000px) {
      width: 50%;
      left: 0px;
      right: unset;
    }
    @media (min-width: 1800px) and (max-width: 1999px) {
      left: unset;
      right: 15%;
      width: 58%;
    }
    @media (min-width: 1700px) and (max-width: 1800px) {
      left: unset;
      right: 8%;
      width: 62%;
    }
    @media (min-width: 1625px) and (max-width: 1700px) {
      left: unset;
      right: 7%;
      width: 62%;
    }
    @media (min-width: 1598px) and (max-width: 1624px) {
      left: 0%;
      width: 38%;
    }
    @media (min-width: 1506px) and (max-width: 1597px) {
      left: 0%;
      width: 50%;
    }
    @media (min-width: 1364px) and (max-width: 1400px) {
      left: 32%;
      right: unset;
      width: 63%;
    }
    @media (min-width: 1356px) and (max-width: 1364px) {
      left: 0%;
      width: 40%;
    }
    @media (min-width: 1300px) and (max-width: 1356px) {
      left: 0%;
      width: 70%;
    }
    @media (min-width: 900px) and (max-width: 1300px) {
      left: 0%;
      width: 55%;
    }
    @media (max-width: 900px) {
      bottom: 0;

      right: 5%;
    }
    @media (min-width: 700px) and (max-width: 900px) {
      width: 32%;
      left: 40%;
    }
    @media (min-width: 850px) and (max-width: 900px) {
      left: unset;
      width: 28%;
      right: 29%;
    }
    @media (min-width: 758px) and (max-width: 850px) {
      left: unset;
      width: 33%;
      right: 25%;
    }
    @media (min-width: 716px) and (max-width: 757px) {
      left: unset;
      width: 35%;
      right: 20%;
    }
    @media (min-width: 621px) and (max-width: 715px) {
      width: 38%;
      left: unset;
      right: 8%;
    }
    @media (min-width: 593px) and (max-width: 620px) {
      right: 5%;
      width: 42%;
      left: unset;
    }
    @media (min-width: 500px) and (max-width: 592px) {
      width: 30%;
      left: 35%;
    }
    @media (min-width: 469px) and (max-width: 499px) {
      width: 48%;
      left: 26%;
    }
    @media (min-width: 380px) and (max-width: 468px) {
      width: 55%;
      right: 8%;
    }
    @media (min-width: 375px) and (max-width: 380px) {
      width: 40%;
      left: 30%;
    }
    @media (min-width: 335px) and (max-width: 375px) {
      left: 18%;
      width: 64%;
    }
  }
`;
const MainButton = styled.button`
  width: 280.58px;
  height: 67px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    91.09deg,
    #7af1b6 0.92%,
    #86e780 46.8%,
    #fbff89 99.83%
  );
  box-shadow: 5px 4px 44px rgba(161, 231, 128, 0.62);
  border-radius: 15px 15px 0px 15px;
  border: none;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #27153e;
  cursor: pointer;
  z-index: 5;
  @media (max-width: 900px) {
    width: 164px;
    height: 40px;
    font-size: 12.5px;
  }
`;
const Stores = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 28px;
  // @media (max-width: 900px) {
  //   display: none;
  // }
`;
const StoreIcon = styled.img`
  object-fit: cover;
  cursor: pointer;
  z-index: 5;
  @media (max-width: 900px) {
    width: 100%;
  }
`;

const A = styled.a`
  text-decoration: none;
`;

export default function Home() {
  return (
    <Wrapper>
      <Navbar />
      <BackgroundImg src={bgImg} alt="background" />
      <Container>
        <Column>
          <Text>
            <TextImage src={goldImg} alt="gold" /> “Over 200 Million digital
            Assets traded”
          </Text>
          <Title>
            Start creating wealth with India’s leader in Online
            <Underline> Gold & Silver.</Underline>
          </Title>
          <MainButton
            onClick={() => window.open("https://my.oropocket.com/login.php")}
          >
            {"Get Started >"}
          </MainButton>

          <Stores>
            <A
              href="https://play.google.com/store/apps/details?id=com.amrbadr12.oro_pocket"
              target="_blank"
            >
              <StoreIcon src={googlePlayImg} alt="google Image" />
            </A>
            <A
              href="https://apps.apple.com/us/app/oropocket/id1496669275"
              target="_blank"
            >
              <StoreIcon src={appleImg} alt="apple image" />
            </A>
          </Stores>
        </Column>
        <Column>
          <BuySection />
        </Column>
      </Container>
    </Wrapper>
  );
}
