import React from "react";
import styled from "styled-components";
import cardIcon from "../../assets/images/cardIcon.svg";
import secureIcon from "../../assets/images/secureIcon.svg";
import globeIcon from "../../assets/images/globeIcon.svg";
import autoInvestIcon from "../../assets/images/autoInvestIcon.svg";
import rupeeIcon from "../../assets/images/rupeeIcon.svg";
import physicalIcon from "../../assets/images/physicalIcon.svg";

const Wrapper = styled.div`
  max-width: 100%;
  position: relative;
  padding: 50px;
  @media (max-width: 900px) {
    padding: 25px;
  }
`;
const Title = styled.h1`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 61px;
  max-width: 50%;
  margin: 0 auto;
  text-align: center;
  background: linear-gradient(267.19deg, #00bcb3 5.03%, #01bbb7 5.03%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  @media (max-width: 900px) {
    font-size: 30px;
    max-width: 100%;
    line-height: 34px;
  }
`;

const Subtext = styled.p`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #27153e;
  text-align: center;
  margin-top: 30px;
  @media (max-width: 900px) {
    font-size: 16px;
    margin-top: 5px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 40px;
  @media (max-width: 900px) {
    flex-direction: column;
    margin-top: 10px;
  }
`;
const Item = styled.div`
  max-width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 20px;
  margin-top: 50px;
  @media (max-width: 900px) {
    width: 100%;
    max-width: 100%;
    margin: 0;
    margin-bottom: 25px;
  }
`;
const Image = styled.img``;
const ItemTitle = styled.h2`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  max-width: 70%;
  text-align: center;
  color: #27153e;
`;
const ItemText = styled.p`
  text-align: justify;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #27153e;
`;

export default function WhySection() {
  return (
    <Wrapper id="features">
      <Title>Why Online Gold & Silver From OroPocket?</Title>
      <Subtext>
        OroPocket allows you to diversify your portfolio with transparency and
        safety that help fuel your wealth creation efforts.
      </Subtext>
      <Container>
        <Item>
          <Image src={cardIcon} alt="cardIcon" />
          <ItemTitle>Asset-based Debit Card</ItemTitle>
          <ItemText>
            Enjoy complete liquidity with OroPocket, Spend your assets anytime,
            and anywhere all across the world. Shop online, offline, or withdraw
            cash at the ATM.
          </ItemText>
        </Item>

        <Item>
          <Image src={secureIcon} alt="secure" />
          <ItemTitle>100% Secure</ItemTitle>
          <ItemText>
            The assets you buy online are allocated as physical assets under
            your direct ownership and stored within fully insured, certified
            vaults.
          </ItemText>
        </Item>
        <Item>
          <Image src={globeIcon} alt="globeIcon" />
          <ItemTitle>Instant Global Transactions</ItemTitle>
          <ItemText>
            Transfer your stored digital assets to anyone globally within
            seconds without any hefty transaction charges.
          </ItemText>
        </Item>

        <Item>
          <Image src={autoInvestIcon} alt="investIcon" />
          <ItemTitle>Auto-invest Plan</ItemTitle>
          <ItemText>
            Put your investments on autopilot with OroPocket's Auto-invest. Set
            your investment preferences, choose your investment frequency, and
            then sit back and watch your personal precious metals vault grow.
          </ItemText>
        </Item>
        <Item>
          <Image src={rupeeIcon} alt="rupeeIcon" />
          <ItemTitle>Buy Online From ₹1 Onwards</ItemTitle>
          <ItemText>
            You can instantly buy digital Gold for as low as Rs 1 on OroPocket
            through a simple one-click process. Speed and convenience come along
            with your purchase. You can register on the mobile app or website,
            validate your account, and buy precious metals in under five
            minutes.
          </ItemText>
        </Item>
        <Item>
          <Image src={physicalIcon} alt="physicalIcon" />
          <ItemTitle>Easily Convert Digital into Physical Gold</ItemTitle>
          <ItemText>
            You can convert your digital gold to 24 karat 99.99% pure physical
            gold anytime. Your delivery comes with free insurance, to ensure
            your coins and bars reach you safely.
          </ItemText>
        </Item>
      </Container>
    </Wrapper>
  );
}
