import * as React from "react";

import { useGoogleReCaptchaV2 } from "../hooks/useGoogleReCaptchaV2";
import styled from "styled-components";
import { RECAPTCHA_KEY } from "../definitions";
const FormButton = styled.button`
  background: #00bead;
  box-shadow: -2px 0px 24px rgba(0, 190, 173, 0.37);
  border-radius: 12px 12px 0px 12px;
  width: 150px;
  border: none;
  height: 49px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  cursor: pointer;
  margin-top: 10px;
  @media (max-width: 900px) {
    height: 45px;
  }
`;

const Text = styled.p`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #00bead;
`;
interface Props {
  name: string;
  email: string;
  message: string;
  address: string;
  error: string;
  setError: (error: string) => void;
}

export const ReCaptchaForm = ({
  name,
  email,
  message,
  address,
  setError,
}: Props) => {
  const [token, setToken] = React.useState<string>();
  const { ReCaptchaBadge, executeReCaptcha } = useGoogleReCaptchaV2({
    siteKey: RECAPTCHA_KEY,
  });

  // validate
  const validate = () => {
    if (
      name.length > 0 &&
      email.length > 0 &&
      message.length > 0 &&
      address.length > 0 &&
      email.includes("@") &&
      email.includes(".")
    ) {
      setError("");
      onButtonClickHandler();
    } else {
      setError("name email message address");
    }
  };

  const onButtonClickHandler = async () => {
    try {
      const token = await executeReCaptcha();
      setToken(token);
      fetch("http://oropocket.com/submit_contat_us.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: name,
          email: email,
          message: message,
          token: token,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
        });
      console.log(token);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <form
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <FormButton
        type="button"
        onClick={() => {
          validate();
        }}
      >
        Send
      </FormButton>
      {token && (
        <Text>
          Thanks for your message, we would get back to you at earliest
        </Text>
      )}
      {ReCaptchaBadge && ReCaptchaBadge}
    </form>
  );
};

export default ReCaptchaForm;
