import React from "react";
import styled from "styled-components";
import Navbar from "../components/Navbar";
import bgImg from "../assets/images/background1.png";
import bgImg2 from "../assets/images/background3.png";

import Footer from "../components/Footer";

const Wrapper = styled.div`
  max-width: 100%;
  position: relative;
  z-index: 22;
  overflow: hidden;
`;
const Title = styled.h1`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 61px;
  background: linear-gradient(267.19deg, #00bcb3 5.03%, #01bbb7 5.03%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-top: 50px;
  @media (max-width: 900px) {
    font-size: 30px;
    line-height: 36px;
    text-align: center;
  }
`;
const BackgroundImg = styled.img`
  position: absolute;
  right: 0;
  top: 0;
  object-fit: cover;
`;
const BackgroundImg2 = styled.img`
  position: absolute;
  left: 0%;
  bottom: 34%;
  object-fit: cover;
  opacity: 0.4;
  z-index: 1;
`;
const Container = styled.div`
  padding: 0px 50px;
  position: relative;
  @media (max-width: 900px) {
    padding: 0px 25px;
  }
`;
const P = styled.p`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-top: 20px;
  color: #27153e;
  z-index: 5;
  @media (max-width: 900px) {
    text-align: center;
  }
`;
const Subtitle = styled.h2`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  margin-top: 100px;
  color: #27153e;
  @media (max-width: 900px) {
    font-size: 26px;
    line-height: 36px;
    text-align: center;
    margin-top: 50px;
  }
`;

export default function RefundPolicy() {
  return (
    <Wrapper>
      <Navbar />
      <BackgroundImg src={bgImg} alt="background" />
      <BackgroundImg2 src={bgImg2} alt="background" />

      <Container>
        <Title>Refund Cancellation Policy</Title>
        <Subtitle>Disputes</Subtitle>
        <P>
          Any dispute or claim relating in any way to your visit to the Platform
          or to products or services sold or distributed by Oropocket or through
          the Platform will be resolved first by arbitration. The Indian
          Arbitration and Conciliation Act of 1996 applies to this agreement. To
          begin an arbitration proceeding, you must send a letter requesting
          arbitration and describing your claim in detail to our address
          available on the Platform. The arbitration will be conducted in Delhi
          by an independent arbitrator appointed by Oropocket. The costs of
          arbitration proceedings will be borne by the party so instructed by
          the arbitrator, on merits of the case. We each agree that any dispute
          resolution proceedings will be conducted only on an individual basis
          and not in a class, consolidated or representative action.
        </P>
        <Subtitle>Gorverning Law And Jurisdiction</Subtitle>
        <P>
          By visiting the Platform, you agree to be bound by the applicable
          Indian laws and State laws, including those pertaining to taxation.
          This Agreement has been executed and delivered in India, and its
          interpretations, validity and performance shall be construed and
          enforced in accordance with the laws of the Republic of India, without
          regard to principles of conflict of laws, and shall be subject to the
          exclusive jurisdiction of courts at Delhi, India.
        </P>
        <Subtitle>Indemnification</Subtitle>
        <P>
          You agree to indemnify, defend and hold Oropocket harmless from all
          claims, damages and expenses (including attorney's fees) made by any
          third party arising out of your content, your use of the Platform,
          your connection to the Platform, your violation of this Agreement, our
          Terms and Conditions of Access and Use or our business policies, and
          the development, operation, maintenance, use and contents of this
          Platform.
        </P>
        <Title>Return and replacement</Title>
        <Subtitle>Return, Replacement And Title</Subtitle>
        <P>
          Oropocket does not take title to returned items until the item arrives
          at our designated address. For more information about our returns and
          replacement, please see our Replacement Policy.
        </P>
        <Subtitle>Replacement Policy</Subtitle>
        <P>
          The following rules will apply regarding replacement of articles
          delivered to the customers:
          <br />
          <br />
          <li>
            Replacement shall be sought in writing no later than five business
            days from date of delivery to the Registered Member;
          </li>
          <li>
            Replacement shall be subject to return of the delivered articles at
            Oropocket’s office address stated for this purpose on the Platform;
          </li>
          <li>
            It shall be the responsibility of the Registered Member to ensure
            safe delivery of articles to Oropocket’s custody. Till such time
            that the receipt of Bullion is acknowledged as “received” by
            Oropocket, the risk of loss of the article shall remain with the
            concerned Registered Member;
          </li>
          <li>
            Replacement shall be subject to handover of the delivered article in
            its original tamper-proof packing, if any, in an intact, un-opened
            and non-mutilated condition. Decision of Ororpocket regarding the
            condition of the tamper-proof packing shall be final and conclusive;
            While Oropocket shall try to replace a returned piece with a close
            substitute, Oropocket shall have the right to replace the returned
            piece with any piece which is similar in composition and equal in
            market value on the date of replacement (although not necessarily
            similar in shape or in design) with the returned piece.
          </li>
        </P>
        <Subtitle>Return And Replacement Policy</Subtitle>
        <P>
          In the event you receive a damaged / defective article or an article
          that does not comply with the specifications as per your original
          order, you are required to get in touch with the customer service team
          within 3 business days from the date of receipt of such delivery.
          <br />
          <br />
          Upon receiving your complaint, Oropocket shall verify the authenticity
          and the nature of the complaint. If Oropocket is convinced that the
          complaint is genuine, Oropocket shall provide a free replacement
          subject to the Product being sealed and is returned in the same tamper
          proof blister packaging in which it was delivered to the Customer.
          However, in the event of frivolous and unjustified complaints
          regarding the quality and content of the products, Oropocket reserves
          the right to pursue necessary legal actions against you and you will
          be solely liable for all costs incurred by Oropocket in this regard.
          <br />
          <br />
          Before accepting shipment of any product, kindly ensure that the
          product’s packaging is not damaged or tampered. If you observe that
          the package is damaged or tampered, request you to refuse to accept
          delivery and inform Oropocket at the earliest. The return process of
          the product may be restricted by Oropocket depending on the nature and
          category of the product.
        </P>
        <Title>Cancellation</Title>
        <P>
          <li>
            You can cancel your order for an order at no cost any time before we
            dispatch the order to our delivery partner from our end and you
            receive an " Order Processed " Confirmation. Digital Gold Order
            can’t be cancelled once gold is credited to your Oropocket wallet.
          </li>

          <li>
            The cancellation of orders that are paid via digital wallets or
            online vouchers or gift cards will not be refunded to your bank
            account. It will be credited to Oropocket Wallet only and can be
            used for future purchases.
          </li>
        </P>
        <Title>Refund Policy</Title>
        <P>
          <li>
            Refunds made through Oropocket wallet, will be processed by reversal
            of the same transaction. This would take around 3-5 working days.
          </li>
          <li>
            In cases of refunds under 15 days return, refund is subject to
            quality confirmation by our skilled quality assurance team and
            verification of product documents like insurance certificate,
          </li>
        </P>
      </Container>
      <Footer />
    </Wrapper>
  );
}
