import React from "react";
import styled from "styled-components";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import trustImg from "../assets/images/trust.png";
import valueImg from "../assets/images/value.png";
import customerImg from "../assets/images/customer.png";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
const Wrapper = styled.div``;
const Title = styled.h1`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 58.9767px;
  line-height: 71px;
  color: #309b9b;
  @media (max-width: 900px) {
    font-size: 28px;
    line-height: 50px;
    text-align: center;
  }
`;
const Description = styled.p`
  max-width: 500px;
  margin-top: 45px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  /* or 129% */
  text-transform: capitalize;
  color: #27153e;
  @media (max-width: 900px) {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    margin-top: 20px;
  }
`;
const Container = styled.div`
  padding: 0px 50px;
  @media (max-width: 900px) {
    padding: 0px 25px;
  }
`;
const EthosCont = styled.div``;
const Ethos = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 80px;
  flex-wrap: wrap;
  @media (max-width: 900px) {
    flex-direction: column;
    gap: 40px;
  }
`;
const EthosTitle = styled.h2`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 61px;
  /* identical to box height */
  margin-top: 100px;
  text-align: center;

  background: linear-gradient(267.19deg, #00bcb3 5.03%, #01bbb7 5.03%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  @media (max-width: 900px) {
    margin-top: 50px;
  }
`;
const EthosItem = styled.div`
  max-width: 280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  background: linear-gradient(
    180deg,
    rgba(237, 255, 246, 0) 13.87%,
    #edfff6 47.38%
  );
  padding: 60px 55px;
`;
const EthosItemTitle = styled.h3``;
const EthosItemDescription = styled.p`
  filter: drop-shadow(0px 0px 14px rgba(0, 0, 0, 0.07));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  position: relative;
  margin-top: 18px;
  text-align: center;
`;
const EthosItemImg = styled.img`
  width: 190px;
  height: 220px;
  opacity: 0.8;
  z-index: 0;
`;

const SignSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
  margin: 70px 0px;
  @media (max-width: 900px) {
    align-items: center;
  }
`;
const SignDescription = styled.p`
  max-width: 650px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  color: #27153e;
  @media (max-width: 900px) {
    text-align: center;
  }
`;
const SignButton = styled.button`
  background: linear-gradient(
    91.09deg,
    #7af1b6 0.92%,
    #86e780 46.8%,
    #fbff89 99.83%
  );
  box-shadow: 5px 4px 44px rgba(161, 231, 128, 0.62);
  border-radius: 15px 15px 0px 15px;
  width: 280.58px;
  height: 67px;
  border: none;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #27153e;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  @media (max-width: 900px) {
    width: 150px;
    height: 45px;
    font-size: 14px;
    line-height: 16px;
  }
`;

function About() {
  return (
    <Wrapper>
      <Navbar />
      <Container>
        <Title>About Us</Title>
        <Description>
          We want to make investing in online assets simpler and more
          transparent so that you can kickstart your wealth creation journey.
        </Description>
        <Description>
          OroPocket is an asset-backed banking solution that enables users to
          invest in online Gold and Silver - as a safe hedge against inflation
          and at the same time utilize these investments for day-to-day
          transactions via UPI.
        </Description>
        <EthosCont>
          <EthosTitle>Our Ethos</EthosTitle>
          <Ethos>
            <EthosItem>
              <EthosItemTitle>Trust</EthosItemTitle>
              <EthosItemImg src={trustImg} />

              <EthosItemDescription>
                100% security and transparency, 0% compromise on anything
              </EthosItemDescription>
            </EthosItem>
            <EthosItem>
              <EthosItemTitle>Trust</EthosItemTitle>
              <EthosItemImg src={valueImg} />

              <EthosItemDescription>
                100% security and transparency, 0% compromise on anything
              </EthosItemDescription>
            </EthosItem>
            <EthosItem>
              <EthosItemTitle>Trust</EthosItemTitle>
              <EthosItemImg src={customerImg} />

              <EthosItemDescription>
                100% security and transparency, 0% compromise on anything
              </EthosItemDescription>
            </EthosItem>
          </Ethos>
        </EthosCont>
        <SignSection>
          <SignDescription>
            OroPocket has derived its name from the combination of the words Oro
            and Pocket, Oro means 'Gold' in Spanish and Pocket has been derived
            from the English word which means the ease of carrying an asset like
            Gold in your pocket.
          </SignDescription>
          <SignButton>
            Sign Up <ArrowForwardIosRoundedIcon sx={{ fontSize: "18px" }} />
          </SignButton>
        </SignSection>
      </Container>

      <Footer />
    </Wrapper>
  );
}

export default About;
