import React from "react";
import styled from "styled-components";

const FormInput = styled.input`
  border: none;
  background: #fff;
  width: 100%;
  height: 60px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 7px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #6a6a6a;
  padding: 0px 10px;
  :focus-within {
    outline: none;
  }
  @media (max-width: 900px) {
    height: 40px;
    padding: 0px;
  }
`;

const InputLabel = styled.p`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  color: #27153e;
`;

interface InputProps {
  label: string;
  type: string;
  id?: string;
  value?: string;
  name: string;
  onChange?: (e: any) => any;
}
export const InputBox = ({
  label,
  type,
  id,
  onChange,
  value,
  name,
}: InputProps) => {
  return (
    <>
      <InputLabel>{label}</InputLabel>
      <FormInput
        placeholder=""
        type={type}
        value={value}
        name={name}
        onChange={onChange}
        id={id}
        // onChange=
      />
    </>
  );
};
