import React, { useEffect } from "react";
import styled from "styled-components";
import goldBars from "../../assets/images/goldBars.svg";
import goldBarsActive from "../../assets/images/goldBarsActive.svg";
import barImg from "../../assets/images/bar.png";
import coindImg from "../../assets/images/coins.png";
import ArrowRightAltRoundedIcon from "@mui/icons-material/ArrowRightAltRounded";
import sealImg from "../../assets/images/seal.svg";
import GoldBarIcon from "../../assets/images/goldBarIcon.svg";
import InputComponent from "./InputComponent";
import sellTabImg from "../../assets/images/SellTab.png";
import sellTabSilverImg from "../../assets/images/SellTabSilver.png";
import coinsTabImg from "../../assets/images/CoinsTab.png";
import coinsTabSilverImg from "../../assets/images/CoinsTabSilver.png";
import { DataObjectOutlined } from "@mui/icons-material";

const Wrapper = styled.div`
  width: 555px;
  background: #f7f7f775;
  border-radius: 11px;
  z-index: 1;
  @media (max-width: 900px) {
    width: 90vw;
  }
`;
const Header = styled.div`
  display: flex;
`;
const Tab = styled.div<{ active: boolean }>`
  height: 60px;
  width: 50%;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  border-radius: 9px 0px 0px 0px;

  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #27153e;

  ${({ active }) => active && `border-bottom: 3px solid #00bead;`}
  ${({ active }) => active && `color: #00bead;`}
`;
const Icon = styled.img`
  margin-right: 5px;
`;
const Body = styled.div`
  padding: 35px 25px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;
const Tabs = styled.div`
  display: flex;
  max-width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 7px 10px;
  border-radius: 7px;
  background: #eef2f9;
`;
const TabItem = styled.div<{ active: boolean }>`
  width: 162.33px;
  height: 45px;
  ${({ active }) => active && `background: #fff;`}
  ${({ active }) => !active && `opacity: 0.5;`}
  border-radius: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #27153e;
  cursor: pointer;
`;
const InfoCont = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 25px 0px;
  width: 100%;
`;
const InfoItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
`;
const InfoTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #27153e;
`;
const InfoValue = styled.div`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-transform: lowercase;
  color: #27153e;
`;
const InfoValueSpan = styled.span`
  font-weight: 400;
`;
const Divider = styled.div`
  background: rgba(0, 0, 0, 0.15);
  height: 80px;
  width: 2px;
`;

const BuyButton = styled.a`
  background: #00bead;
  border-radius: 9px 12px 0px;
  border: none;
  width: 150px;
  height: 60px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 20px;
  @media (max-width: 900px) {
    margin-top: 30px;
    height: 45px;
  }
`;
const SellTabImg = styled.img`
  width: 50%;
  height: 130px;
  object-fit: contain;
`;
const SellTabCont = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 45px;
  gap: 33px;
`;
const SellTabText = styled.h4`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20.5431px;
  line-height: 24px;
  text-align: center;
  width: 50%;
  color: #424242;
`;

type Prices = {
  GOLD_INDIA: any;
  SILVER_INDIA: any;
};

export default function BuySection() {
  const [activeTab, setActiveTab] = React.useState("Gold");
  const [activeTabIndex, setActiveTabIndex] = React.useState(0);
  const [currency, setCurrency] = React.useState("₹ INR");
  const [value, setValue] = React.useState("");
  const [value2, setValue2] = React.useState("");
  const [currentPrices, setCurrentPrices] = React.useState({} as Prices);

  useEffect(() => {
    const fetchPrice = async (token: any) => {
      const response = await fetch(
        "https://my.oropocket.com/restAPI/asset_price.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token.token,
          },
          body: JSON.stringify({
            request_code: "72758a2ew9d3d516",
            email: "sahus8349@gmail.com",
          }),
        }
      );
      const data = await response.json();
      setCurrentPrices(data.asset.indian_vault);
    };
    const fetchToken = async () => {
      const response = await fetch(
        "https://my.oropocket.com/restAPI/authentication.php",
        {
          method: "POST",
          body: JSON.stringify({
            secret_key: "6LdK8_wUAAAAADJpnz5hAbMFo02RASh_sBxaoDIs",
            api_id: "92878ff5ac678faa2c997ad3ad35c147",
            request_code: "778fa22123d35c146",
          }),
        }
      );
      fetchPrice(await response.json());
    };

    fetchToken();
    setActiveTabIndex(0);
    setValue("");
    setValue2("");
  }, [activeTab]);
  console.log(currentPrices);
  return (
    <Wrapper>
      <Header>
        <Tab active={activeTab === "Gold"} onClick={() => setActiveTab("Gold")}>
          <Icon
            src={activeTab === "Gold" ? goldBarsActive : goldBars}
            alt="bars"
          />
          Gold
        </Tab>
        <Tab
          active={activeTab === "Silver"}
          onClick={() => setActiveTab("Silver")}
        >
          <Icon
            src={activeTab === "Silver" ? goldBarsActive : goldBars}
            alt="bars"
          />
          Silver
        </Tab>
      </Header>
      <Body>
        <Tabs>
          <TabItem
            active={activeTabIndex === 0}
            onClick={() => setActiveTabIndex(0)}
          >
            <ArrowRightAltRoundedIcon sx={{ transform: "rotateZ(118deg)" }} />
            <Icon src={barImg} alt="bar" />
            Buy
          </TabItem>
          <TabItem
            active={activeTabIndex === 1}
            onClick={() => setActiveTabIndex(1)}
          >
            <ArrowRightAltRoundedIcon sx={{ transform: "rotateZ(300deg)" }} />
            <Icon src={barImg} alt="bar" />
            Sell
          </TabItem>
          <TabItem
            active={activeTabIndex === 2}
            onClick={() => setActiveTabIndex(2)}
          >
            <Icon src={coindImg} alt="bar" />
            Coins
          </TabItem>
        </Tabs>
        {activeTabIndex === 0 && (
          <>
            <InfoCont>
              <InfoItem>
                <InfoTitle>
                  <Icon src={GoldBarIcon} alt="bar" />
                  Live price
                </InfoTitle>
                <InfoValue>
                  <>
                    {currency === "$ USD" ? "$" : "₹ "}
                    {activeTab === "Silver"
                      ? currency === "$ USD"
                        ? currentPrices.SILVER_INDIA?.USD?.buy
                        : currentPrices.SILVER_INDIA?.INR?.buy
                      : currency === "$ USD"
                      ? currentPrices.GOLD_INDIA?.USD?.buy
                      : currentPrices.GOLD_INDIA?.INR?.buy}
                    /gm
                    {/* <InfoValueSpan>+ GST</InfoValueSpan> */}
                  </>
                </InfoValue>
              </InfoItem>
              <Divider />

              <InfoItem>
                <InfoTitle>
                  <Icon src={sealImg} alt="purity icon" /> Purity
                </InfoTitle>
                <InfoValue> 99.99%</InfoValue>
              </InfoItem>
            </InfoCont>
            <InputComponent
              currency={currency}
              value={value}
              setValue={setValue}
              value2={value2}
              setValue2={setValue2}
              setCurrency={setCurrency}
              price={
                activeTab === "Silver"
                  ? currency === "$ USD"
                    ? currentPrices.SILVER_INDIA?.USD?.buy
                    : currentPrices.SILVER_INDIA?.INR?.buy
                  : currency === "$ USD"
                  ? currentPrices.GOLD_INDIA?.USD?.buy
                  : currentPrices.GOLD_INDIA?.INR?.buy
              }
            />
            {/* <ExchangeIcon>
              <ArrowRightAltRoundedIcon
                sx={{
                  color: "#27153E",
                  transform: "rotate(90deg)",
                  fontSize: "3rem",
                  marginLeft: "-2rem",
                }}
              />
            </ExchangeIcon> */}
          </>
        )}
        {activeTabIndex === 1 && (
          <SellTabCont>
            <SellTabImg
              src={activeTab === "Gold" ? sellTabImg : sellTabSilverImg}
              alt="gold bar"
            />
            <SellTabText>
              {activeTab === "Gold"
                ? "  Create An Account To Buy And Sell 24K Gold"
                : "  Create An Account To Buy And Sell 24K Silver"}
            </SellTabText>
          </SellTabCont>
        )}
        {activeTabIndex === 2 && (
          <SellTabCont>
            <SellTabImg
              src={activeTab === "Gold" ? coinsTabImg : coinsTabSilverImg}
              alt="gold bar"
            />
            <SellTabText>
              {activeTab === "Gold"
                ? "Create An Account To Buy Gold Coins"
                : "Create An Account To Buy Silver Coins"}
            </SellTabText>
          </SellTabCont>
        )}
        <BuyButton href="https://my.oropocket.com/login.php">
          {activeTabIndex === 0 ? "Buy now" : "Go to App"}
        </BuyButton>
      </Body>
    </Wrapper>
  );
}
