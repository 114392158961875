import React from "react";
import bgImg from "../../assets/images/background2.png";
import mobileImg from "../../assets/images/mobile.png";
import mobileImg2 from "../../assets/images/mobile2.png";
import mobileImg3 from "../../assets/images/mobile3.png";

import mobileIcon from "../../assets/images/mobileIcon.png";
import mobileIcon2 from "../../assets/images/mobileIcon2.png";
import mobileIcon3 from "../../assets/images/mobileIcon3.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import styled from "styled-components";

const Wrapper = styled.div`
  max-width: 100%;
  position: relative;
  padding: 50px;
  @media (max-width: 900px) {
    padding: 25px;
  }
`;
const Title = styled.h1`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 61px;
  text-align: center;
  background: linear-gradient(267.19deg, #00bcb3 5.03%, #01bbb7 5.03%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  @media (max-width: 900px) {
    font-size: 30px;
  }
`;
const BackgroundImg = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  object-fit: cover;
  z-index: 0;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 25px 0px;
  gap: 10%;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;
const Row = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  @media (max-width: 900px) {
    width: 100%;
  }
`;
const Image = styled.img`
  z-index: 5;
  margin-left: 10%;
  @media (max-width: 900px) {
    width: 85%;
    margin-left: 0;
  }
`;
const MobileInfoCont = styled.div<{ active: boolean; index: number }>`
  width: 380px;
  height: 110px;
  background: rgb(255 255 255 / 84%);
  border-radius: 10px;
  z-index: 9;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  left: 5%;
  padding: 0px 10px;
  display: ${(props) => (props.active ? "flex" : "none")};
  ${(props) =>
    props.index === 1 &&
    `
  top: 42%;
      
      `}
  ${(props) =>
    props.index === 0 &&
    `
  top: 15%;
      
      `}
      ${(props) =>
    props.index === 2 &&
    `
  top: 65%;
      
      `}
  @media (max-width: 900px) {
    width: 90%;
    height: auto;
    left: 0px;
    padding: 10px 0px;
    top: 12%;
  }
`;
const MobileInfoIcon = styled.img`
  width: 70px;
  height: 70px;
  @media (max-width: 900px) {
    width: 40px;
    height: 40px;
  }
`;
const MobileInfoTexts = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
`;
const MobileInfoTitle = styled.h3`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #27153e;
  @media (max-width: 900px) {
    font-size: 14px;
  }
`;
const MobileInfoText = styled.p`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #27153e;
  @media (max-width: 900px) {
    font-size: 12px;
  }
`;
const InfoCont = styled.div<{ active: boolean }>`
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-shadow: 8px 8px 54px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  width: 400px;
  height: 100px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 25px;
  gap: 20px;
  ${({ active }) =>
    !active &&
    `
  opacity: 0.7;
  margin-left: 60px;
  `}
  @media (max-width: 900px) {
    ${({ active }) =>
      !active &&
      `
  display: none;
  `}
    width: 90%;
    padding: 15px;
    gap: 10px;
  }
`;
const InfoTitle = styled.h3`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #27153e;
  display: flex;
  align-items: center;
  gap: 10px;
  @media (max-width: 900px) {
    font-size: 16px;
    gap: 6px;
  }
`;
const InfoText = styled.p`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #27153e;
  @media (max-width: 900px) {
    font-size: 14px;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
`;
export default function Simplified() {
  const [isMouseOver, setIsMouseOver] = React.useState(false);
  const [activeIndex, setActiveIndex] = React.useState<any>(1);
  // const [scrollPostion, setScrollPostion] = React.useState(0);

  const handleOnMouseEnter = () => {
    setIsMouseOver(true);
  };

  const handleOnMouseLeave = () => {
    setIsMouseOver(false);
  };

  function handleWheel(e: React.WheelEvent) {
    if (isMouseOver) {
      var nextImageIndex: number;

      // The following condition
      // finds the next image
      // index depending if we
      // scroll up or scroll down
      if (e.deltaY > 0) {
        nextImageIndex = (activeIndex + 1) % 3;
      } else {
        nextImageIndex = (activeIndex - 1 + 3) % 3;
      }
      setTimeout(() => {
        setActiveIndex(nextImageIndex);
      }, 500);
    
    }

    // const delta = e.deltaY;

    // setScrollPostion((prev) => prev + delta);
    // window.scrollTo({
    //   top: scrollPostion,
    //   behavior: "smooth",
    // });
  }

  return (
    <Wrapper
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      onWheel={(e) => handleWheel(e)}
    >
      <BackgroundImg src={bgImg} alt="background" />

      <Title>Gold & Silver Simplified</Title>

      <Container>
        <Row>
          <Image
            src={
              activeIndex === 1
                ? mobileImg
                : activeIndex === 0
                ? mobileImg2
                : mobileImg3
            }
            alt="moneyControl"
            className="fade-in"
          />
          <MobileInfoCont active={activeIndex === 0} index={activeIndex} className="fade-in">
            <MobileInfoIcon src={mobileIcon2} alt="coins" />
            <MobileInfoTexts>
              <MobileInfoTitle>Easy buy Process </MobileInfoTitle>
              <MobileInfoText>
                Start Creating Wealth with India’s leader in Digital Gold &
                Silver.
              </MobileInfoText>
            </MobileInfoTexts>
          </MobileInfoCont>
          <MobileInfoCont active={activeIndex === 1} index={activeIndex} className="fade-in">
            <MobileInfoIcon src={mobileIcon} alt="coins" />
            <MobileInfoTexts>
              <MobileInfoTitle>Buy Gold and Silver easily</MobileInfoTitle>
              <MobileInfoText>
                Start Creating Wealth with India’s leader in Digital Gold &
                Silver.
              </MobileInfoText>
            </MobileInfoTexts>
          </MobileInfoCont>
          <MobileInfoCont active={activeIndex === 2} index={activeIndex} className="fade-in">
            <MobileInfoIcon src={mobileIcon3} alt="coins" />
            <MobileInfoTexts>
              <MobileInfoTitle>Your assets are 100% secured</MobileInfoTitle>
              <MobileInfoText>
                Start Creating Wealth with India's leader in Digital Gold &
                Silver.
              </MobileInfoText>
            </MobileInfoTexts>
          </MobileInfoCont>
        </Row>
        <Row>
          <InfoContainer>
            <InfoCont active={activeIndex === 0}>
              <InfoTitle>
                <CheckCircleIcon /> Choose your payment method
              </InfoTitle>
              <InfoText>
                Pay through your preferred online payment option cards, UPI, or
                net banking.
              </InfoText>
            </InfoCont>
            <InfoCont active={activeIndex === 1}>
              <InfoTitle>
                <CheckCircleIcon /> Enter amount in INR or grams
              </InfoTitle>
              <InfoText>
                You can buy gold/silver of a fixed value or weight at live
                market rates.
              </InfoText>
            </InfoCont>
            <InfoCont active={activeIndex === 2}>
              <InfoTitle>
                <CheckCircleIcon /> Your gold/silver is now 100% secured
              </InfoTitle>
              <InfoText>
                Your assets are 100% insured & backed by 1:1 physical assets
                stored in Vaults in UK, Switzerland, Singapore & India.
              </InfoText>
            </InfoCont>
          </InfoContainer>
        </Row>
      </Container>
    </Wrapper>
  );
}
