import styled from "styled-components";
import { MediaBreakpoints } from "../../constants/MediaBreakpoints";
import { TextField } from "@mui/material";

export const EventContainer = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 15px;
`;

export const HeroSectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (${MediaBreakpoints.Mobile}) {
    flex-direction: column;
  }
  @media (${MediaBreakpoints.ipad}) {
    flex-direction: column;
  }
`;

export const BackgroundContainer = styled.div`
  background-color: #f1f1f1;
  padding: 4rem;

  @media (${MediaBreakpoints.Mobile}) {
    padding: 0.5rem 0;
  }

  @media (${MediaBreakpoints.ipad}) {
    padding: 1rem;
  }
`;

interface JustifyProps {
  isJustifyStart?: boolean;
}

export const EqualDiv = styled.div<JustifyProps>`
  width: 50%;
  display: flex;
  justify-content: ${(props) => (props.isJustifyStart ? "start" : "end")};
  align-items: center;

  @media (${MediaBreakpoints.Mobile}) {
    width: 100%;
    justify-content: center;
  }

  @media (${MediaBreakpoints.ipad}) {
    width: 100%;
    justify-content: center;
  }
`;

export const EventTheme = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 58px;
  letter-spacing: 1.2px;
  width: 70%;
  color: #27153e;

  @media (${MediaBreakpoints.Mobile}) {
    line-height: 29px;
    font-size: 24px;
    width: 100%;
    text-align: center;
  }

  @media (${MediaBreakpoints.ipad}) {
    font-size: 48px;
    width: 100%;
    text-align: center;
  }
`;

export const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (${MediaBreakpoints.Mobile}) {
    justify-content: center;
    flex-direction: column;
    gap: 10px;
  }
`;

export const EventTitle = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 58px;
  /* identical to box height */

  color: #27153e;

  @media (${MediaBreakpoints.Mobile}) {
    line-height: 29px;
    font-size: 24px;
    width: 100%;
    text-align: center;
  }
`;

export const EventCardContainer = styled.div`
  margin-top: 60px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 30px;

  @media (${MediaBreakpoints.Mobile}) {
    margin-top: 30px;
    row-gap: 20px;
  }
  @media (${MediaBreakpoints.ipad}) {
    margin-top: 30px;
    justify-content: center;
    column-gap: 20px;
  }
`;

export const EventCard = styled.div`
  width: calc(33.33% - 20px);

  background-color: #ffffff;
  border-radius: 16px;
  @media (${MediaBreakpoints.Mobile}) {
    width: 100%;
  }

  @media (${MediaBreakpoints.ipad}) {
    width: calc(50% - 20px);
  }
`;

export const InsideDiv = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 16px;
`;

export const CardHeader = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #2c404d;
  cursor: pointer;
`;

export const WebinarTag = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px 8px;
  background: #00bbbc;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #f1f1f1;
`;

export const HostTag = styled(WebinarTag)`
  background: #74f5cf;
`;
export const EventTag = styled(WebinarTag)`
  background: #cf82f3;
`;

export const Flexbox = styled.div`
  display: flex;
  gap: 10px;
`;

export const LearnMore = styled.a`
  text-decoration: none;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  position: relative;
  color: #5cd8cd;
  cursor: pointer;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0px;
    left: 0;
    background-color: #5cd8cd;
    transform-origin: bottom left;
    transition: transform 0.25s ease-out;
    transform: scaleX(0);
  }

  &:hover::after {
    transform: scaleX(1);
  }

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 16px;
    /* identical to box height, or 114% */

    display: flex;
    align-items: center;

    /* Primary/Brand */
  }
`;

export const LogoContainer = styled.div`
  width: 20px;
  height: 20px;
`;

export const Image = styled.img`
  width: 90%;
  height: 90%;
  object-fit: contain;
`;

export const SmallText = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #2c404d;
  margin-left: 5px;
`;

export const SearchTextField = styled(TextField)`
  width: 26%;
  // height: 72px;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
  @media ${MediaBreakpoints.ipad} {
    width: 50%;
  }
  & .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.23) !important;
  }
  & input {
    color: #879094;
    padding: 12.5px 0;
    ::placeholder {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 23px;
      display: flex;
      align-items: center;
      text-transform: capitalize;

      color: #bababa;
    }
  }

  & .MuiOutlinedInput-root {
    border: 1px solid #d2e0e7;
    border-radius: 7px;

    // height: 72px;
    @media (max-width: 768px) {
      width: 100%;
      height: auto;
    }
  }
`;

export const EventContentWrapper = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  width: 668px;
  gap: 16px;
  @media (max-width: 768px) {
    width: 80%;
    padding: 20px;
  }
`;

export const BannerWrapper = styled.div`
  width: 604px;
  height: 280px;
`;

export const BannerImage = styled.img`
  width: 100%;
  height: 80%;
  object-fit: contain;
`;

export const ModalContent = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  /* or 188% */

  color: #2c404d;

  @media (max-width: 768px) {
    line-height: 20px;
  }
`;

export const CodeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 16px;
  justify-content: center;
  gap: 8px;

  width: 145px;
  height: 38px;

  /* Secondary/Light */

  border: 1px dashed #9eabb1;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15));
  border-radius: 8px;
`;

export const CodeText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  /* identical to box height, or 188% */

  display: flex;
  align-items: center;

  /* Primary/Silver */

  color: #2C404D
  :hover {
    cursor: pointer;
  }
`;

export const IconWrap = styled.div`
  width: 20px;
  height: 20px;
`;

export const MoreText = styled.span`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */
  color: #5cd8cd;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const EventDetailsWrapper = styled(Flexbox)`
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 20px;
`;

export const ImageWrapper = styled.div`
  width: 100px;
  height: 100px;
`;

export const EmptyHeading = styled.p`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  /* identical to box height */

  text-align: center;

  /* Primary/Light */

  color: #27153e;
`;

export const SubHeading = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  /* identical to box height, or 188% */
  margin: 0;
  text-align: center;

  /* Secondary/Light */

  color: #2c404d;
`;
