import React from "react";
import styled from "styled-components";
import phoneImg from "../../assets/images/phone.png";
import googlePlayImg from "../../assets/images/GooglePlay.png";
import appleImg from "../../assets/images/AppStore.png";

const Wrapper = styled.div`
  max-width: 100%;
  position: relative;
  padding: 50px;
  z-index: 1;
  overflow-x: hidden;
  @media (max-width: 900px) {
    padding: 25px;
    padding-bottom: 100px;
    height: 150%;
  }
`;
const Title = styled.h1`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 61px;
  background: linear-gradient(267.19deg, #00bcb3 5.03%, #01bbb7 5.03%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  @media (max-width: 900px) {
    font-size: 30px;
    line-height: 36px;
    text-align: center;
  }
`;
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 900px) {
    flex-direction: column-reverse;
  }
`;
const Row = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 35px;
  @media (max-width: 900px) {
    width: 100%;
    gap: 10px;
  }
`;
const Image = styled.img`
  z-index: 5;
  margin-left: 10%;
  max-width: 80%;
  object-fit: contain;
  @media (max-width: 900px) {
    margin: 30px auto;
  }
`;
const Description = styled.p`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #27153e;
  @media (max-width: 900px) {
    text-align: center;
  }
`;
const Stores = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 28px;
  @media (max-width: 900px) {
    display: none;
  }
`;
const StoresMobile = styled.div`
  display: none;
  align-items: center;
  justify-content: flex-start;
  gap: 28px;
  @media (max-width: 900px) {
    display: flex;

    justify-content: space-evenly;
  }
`;
const StoreIcon = styled.img`
  object-fit: cover;
  cursor: pointer;
  z-index: 5;
  @media (max-width: 900px) {
    width: 45%;
  }
`;
const A = styled.a`
  text-decoration: none;
`;
export default function MobileAppComponent() {
  return (
    <Wrapper>
      <Container>
        <Row>
          <Image src={phoneImg} alt="phone" />
          <StoresMobile>
            <StoreIcon src={googlePlayImg} alt="google Image" />

            <StoreIcon src={appleImg} alt="apple image" />
          </StoresMobile>
        </Row>
        <Row>
          <Title>Manage Your Finance With OroPocket Mobile App</Title>
          <Description>
            Download the OroPocket app to get 1mg Gold for free!
          </Description>
          <Stores>
            <A
              href="https://play.google.com/store/apps/details?id=com.amrbadr12.oro_pocket"
              target="_blank"
            >
              <StoreIcon src={googlePlayImg} alt="google Image" />
            </A>
            <A
              href="https://apps.apple.com/us/app/oropocket/id1496669275"
              target="_blank"
            >
              <StoreIcon src={appleImg} alt="apple image" />
            </A>
          </Stores>
        </Row>
      </Container>
    </Wrapper>
  );
}
