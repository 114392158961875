import React from "react";
import styled from "styled-components";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import cardIcon from "../../assets/images/smallCardIcon.svg";
import smallAssets from "../../assets/images/smallAssetsIcon.svg";
import globeIcon from "../../assets/images/smallGlobeIcon.svg";
import autoInvestIcon from "../../assets/images/smallAutoInvestIcon.svg";
import smallStorageIcon from "../../assets/images/smallStorageIcon.svg";
import rupeeIcon from "../../assets/images/smallRupeeIcon.svg";
import physicalIcon from "../../assets/images/smallPhysicalIcon.svg";
import bgImg from "../../assets/images/background3.png";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";

const BackgroundImg = styled.img`
  position: absolute;
  left: -5%;
  top: 34%;
  object-fit: cover;
  opacity: 0.4;
  z-index: 0;
  @media (max-width: 900px) {
    display: none;
  }
`;
const Wrapper = styled.div`
  max-width: 100%;
  position: relative;
  padding: 50px;
  z-index: 1;
  @media (max-width: 900px) {
    padding: 25px;
  }
`;
const Title = styled.h1`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 61px;
  max-width: 70%;
  margin: 0 auto;
  text-align: center;
  background: linear-gradient(267.19deg, #00bcb3 5.03%, #01bbb7 5.03%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  @media (max-width: 900px) {
    font-size: 30px;
    line-height: 36px;
  }
`;
const Table = styled.div`
  width: 100%;
  margin-top: 80px;
  @media (max-width: 900px) {
    margin-top: 10px;
    overflow-x: scroll;
  }
`;
const Row = styled.div`
  display: flex;
  max-width: 100%;
  align-items: flex-end;
  justify-content: space-evenly;
  padding: 40px 40px;
  margin: 0px 25px;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.06);
  @media (max-width: 900px) {
    margin: 0px 0px;
    padding: 20px 0px;
    width: 90%;
    max-width: none;
    padding-left: 190px;
  }
  @media (min-width: 700px) and (max-width: 900px) {
    width: 100%;
  }
`;

const Cell = styled.div`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #27153e;
  width: 12%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 900px) {
    font-size: 10px;
    line-height: 12px;
  }
`;
const HeadCell = styled.div`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #27153e;
  margin-bottom: 56px;
  @media (max-width: 900px) {
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 30px;
  }
`;
const TitleCell = styled.h2`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #27153e;
  width: unset;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  width: 33%;
  @media (min-width: 1400px) {
    width: 20%;
  }
  @media (max-width: 900px) {
    font-size: 12px;
    line-height: 14px;
    width: 150px;
    position: absolute;
    z-index: 7;
    background: #fff;
    left: 25px;
    gap: 8px;
  }
`;
const StyledCheckCircleIcon = styled(CheckCircleIcon)`
  color: #38a3a5;
  font-size: 33px;
`;

const StyledCancelIcon = styled(CancelIcon)`
  color: #fc4f4f;
  font-size: 33px;
`;
const Icon = styled.img`
  width: 23px;
  height: 23px;
`;

const EmptyCell = styled.div`
  width: 25%;
`;
export default function TableComponent() {
  return (
    <Wrapper>
      <Title>OroPocket Vs Other Platforms</Title>
      <BackgroundImg src={bgImg} alt="background" />

      <Table>
        <Row>
          <TitleCell style={{ paddingTop: "40px" }}>
            <Icon src={globeIcon} alt="globe" />
            International Transfers
            <Tooltip title="Send Gold to friends and relatives in over 200 countries and territories across the world using the OroPocket app.">
              <InfoOutlinedIcon />
            </Tooltip>
          </TitleCell>
          <Cell>
            <HeadCell>OroPocket</HeadCell>
            <StyledCheckCircleIcon />
          </Cell>
          <Cell>
            <HeadCell>Safegold</HeadCell>
            <StyledCancelIcon />
          </Cell>
          <Cell>
            <HeadCell>Paytm</HeadCell>
            <StyledCancelIcon />
          </Cell>
          <Cell>
            <HeadCell>OneGold</HeadCell>
            <StyledCancelIcon />
          </Cell>
        </Row>
        <Row>
          <TitleCell>
            <Icon src={autoInvestIcon} alt="auto" />
            Auto invest
            <Tooltip title="Put your investments on autopilot with OroPocket's Auto Invest. Set your investing preferences, frequency of investments, and then sit back and watch your personal gold and silver vault develop.">
              <InfoOutlinedIcon />
            </Tooltip>
          </TitleCell>
          <Cell>
            <StyledCheckCircleIcon />
          </Cell>
          <Cell>
            <StyledCheckCircleIcon />
          </Cell>
          <Cell>
            <StyledCancelIcon />
          </Cell>
          <Cell>
            <StyledCancelIcon />
          </Cell>
        </Row>
        <Row>
          <TitleCell>
            <Icon src={smallStorageIcon} alt="storage" />
            No storage fees
            <Tooltip title="100% Secure Vaulting Facility of 24K 999.9 Purest Gold & Silver with no storage fees.">
              <InfoOutlinedIcon />
            </Tooltip>
          </TitleCell>
          <Cell>
            <StyledCheckCircleIcon />
          </Cell>
          <Cell>
            <StyledCancelIcon />
          </Cell>
          <Cell>
            <StyledCheckCircleIcon />
          </Cell>
          <Cell>
            <StyledCancelIcon />
          </Cell>
        </Row>
        <Row>
          <TitleCell>
            <Icon src={rupeeIcon} alt="rupee" />
            As low as Rs 1
            <Tooltip title="You can instantly buy digital Gold for as low as Rs 1 on OroPocket through a simple one-click process.">
              <InfoOutlinedIcon />
            </Tooltip>
          </TitleCell>
          <Cell>
            <StyledCheckCircleIcon />
          </Cell>
          <Cell>
            <StyledCancelIcon />
          </Cell>
          <Cell>
            <StyledCheckCircleIcon />
          </Cell>
          <Cell>
            <StyledCancelIcon />
          </Cell>
        </Row>
        <Row>
          <TitleCell>
            <Icon src={cardIcon} alt="rupee" />
            Asset based debit card
            <Tooltip title="Enjoy complete liquidity with OroPocket debit, card. Spend your assets anytime, and anywhere all across the world.Shop online, offline, or withdraw cash at the ATM.">
              <InfoOutlinedIcon />
            </Tooltip>
          </TitleCell>
          <Cell>
            <StyledCheckCircleIcon />
          </Cell>
          <Cell>
            <StyledCancelIcon />
          </Cell>
          <Cell>
            <StyledCancelIcon />
          </Cell>
          <Cell>
            <StyledCancelIcon />
          </Cell>
        </Row>

        <Row>
          <TitleCell>
            <Icon src={physicalIcon} alt="rupee" />
            Guaranteed 24k Gold
            <Tooltip title="The assets you buy online are allocated as physical assets under your direct ownership and stored within fully insured, certified vaults.">
              <InfoOutlinedIcon />
            </Tooltip>
          </TitleCell>
          <Cell>
            <StyledCheckCircleIcon />
          </Cell>
          <Cell>
            <StyledCheckCircleIcon />
          </Cell>
          <Cell>
            <StyledCheckCircleIcon />
          </Cell>
          <Cell>
            <StyledCancelIcon />
          </Cell>
        </Row>

        <Row>
          <TitleCell>
            <Icon src={smallAssets} alt="rupee" />
            Other asset classes
            <Tooltip title="Invest into multiple asset classes like platinum, ruby, startups, beer, wine, whiskey, etc. - grow your wealth, enjoy 100% liquidity.">
              <InfoOutlinedIcon />
            </Tooltip>
          </TitleCell>
          <Cell>
            <StyledCheckCircleIcon />
          </Cell>
          <Cell>
            <StyledCancelIcon />
          </Cell>
          <Cell>
            <StyledCancelIcon />
          </Cell>
          <Cell>
            <StyledCheckCircleIcon />
          </Cell>
        </Row>
      </Table>
    </Wrapper>
  );
}
