import styled from "styled-components";
import { StyledAccordian } from "../components/FAQ/Accordian";
import Navbar from "../components/Navbar";
import bgImg from "../assets/images/background1.png";
import Footer from "../components/Footer";

const Wrapper = styled.div`
  max-width: 100%;
  position: relative;
  z-index: 22;
  overflow: hidden;
  @media (max-width: 900px) {
    width: 100%;
    max-width: unset;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 0 25px;
  @media (max-width: 900px) {
    flex-direction: column;
    padding: 0px 25px;
  }
`;

const FaqWrapper = styled.div`
  display: flex;
  // height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  margin-bottom: 80px;
`;
export const AccordianWrapper = styled.div`
  width: 800px;
  @media (max-width: 425px) {
    width: 300px;
  }
`;

export const AboutTitle = styled.div`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  // text-align: center;
  line-height: 110px;
  /* identical to box height */

  letter-spacing: -0.03em;

  /* Font/header */

  color: #0f1f4a;
  @media (max-width: 425px) {
    font-size: 40px;
  }
`;

const BackgroundImg = styled.img`
  position: absolute;
  right: 0;
  top: -10%;
  object-fit: cover;
`;

export const Faq = () => {
  return (
    <>
      <Wrapper>
        <Navbar />
        <BackgroundImg src={bgImg} alt="background" />
        <Container>
          <FaqWrapper>
            <AboutTitle>FAQs</AboutTitle>
            <AccordianWrapper>
              <StyledAccordian />
            </AccordianWrapper>
          </FaqWrapper>
        </Container>
        <Footer />
      </Wrapper>
    </>
  );
};
