import React from "react";
import styled from "styled-components";
import Navbar from "../components/Navbar";
import bgImg from "../assets/images/background1.png";
import bgImg2 from "../assets/images/background3.png";

import Footer from "../components/Footer";

const Wrapper = styled.div`
  max-width: 100%;
  position: relative;
  z-index: 22;
  overflow: hidden;
`;
const Title = styled.h1`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 61px;
  background: linear-gradient(267.19deg, #00bcb3 5.03%, #01bbb7 5.03%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  @media (max-width: 900px) {
    font-size: 30px;
    line-height: 36px;
    text-align: center;
  }
`;
const BackgroundImg = styled.img`
  position: absolute;
  right: 0;
  top: 0;
  object-fit: cover;
`;
const BackgroundImg2 = styled.img`
  position: absolute;
  left: 0%;
  bottom: 34%;
  object-fit: cover;
  opacity: 0.4;
  z-index: 1;
`;
const Container = styled.div`
  padding: 0px 50px;
  position: relative;
  @media (max-width: 900px) {
    padding: 0px 25px;
  }
`;
const P = styled.p`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-top: 20px;
  color: #27153e;
  z-index: 5;
  @media (max-width: 900px) {
    text-align: center;
  }
`;
const Subtitle = styled.h2`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  margin-top: 40px;
  color: #27153e;
  @media (max-width: 900px) {
    font-size: 26px;
    line-height: 32px;
    text-align: center;
    margin-top: 50px;
  }
`;

export default function PrivacyPolicy() {
  return (
    <Wrapper>
      <Navbar />
      <BackgroundImg src={bgImg} alt="background" />
      <BackgroundImg2 src={bgImg2} alt="background" />

      <Container>
        <Title>Privacy Policy for Oropocket</Title>
        <P>
          At Oropocket, accessible from https://oropocket.com, one of our main
          priorities is the privacy of our visitors. This Privacy Policy
          document contains types of information that is collected and recorded
          by Oropocket and how we use it.
          <br />
          If you have additional questions or require more information about our
          Privacy Policy, do not hesitate to contact us.
        </P>
        <Subtitle>General Data Protection Regulation (GDPR)</Subtitle>
        <P>
          We are a Data Controller of your information.
          <br />
          <br />
          Oropocket legal basis for collecting and using the personal
          information described in this Privacy Policy depends on the Personal
          Information we collect and the specific context in which we collect
          the information:
          <br />
          <br />
          <li>Oropocket needs to perform a contract with you</li>
          <br />
          <li>You have given Oropocket permission to do so</li>
          <br />
          <li>
            Processing your personal information is in Oropocket legitimate
            interests
          </li>
          <br />
          <li>
            Oropocket needs to comply with the law Oropocket will retain your
            personal information only for as long as is necessary for the
            purposes set out in this Privacy Policy.
          </li>
          <br />
          <li>
            We will retain and use your information to the extent necessary to
            comply with our legal obligations, resolve disputes, and enforce our
            policies.
          </li>
          <br />
          <li>
            Oropocket needs permission for image notifications allow our app to
            access and display images in notification banners.This is used to
            accompany notifications with images for offers and other benefits.
          </li>
          <br />
          <li>
            Oropocket needs permission to access photos in order to scan QR
            codes for payments because the QR code image contains information
            that is necessary for the payment process. When user wants to make a
            payment using a QR code, they will need to use QR codes available on
            their phones. In order to do this, the app will need to access the
            device’s camera and photos. The app will then use this image to
            extract the necessary payment information from the QR code and
            complete the payment. Without permission to access the device’s
            photos, the app would not be able to scan the QR code and complete
            the payment
          </li>
          <br />
          <li>
            Oropocket needs permission to access users messages to verify their
            identity and protect their account security. Many online services,
            including banks and other financial institutions, use one-time
            passcodes (OTPs) as an additional layer of security. By accessing
            users messages, Oropocket can automatically detect and verify OTPs
            sent to them, which makes it easier for users to log in and access
            their accounts. This helps to reduce the risk of unauthorized access
            and ensures that only the intended users can access their accounts.
            Additionally, access to users messages can allow your app to detect
            and respond to other security-related messages, such as alerts about
            suspicious activity on their account.
          </li>
          <br />
          <li>
            Oropocket also needs permission to access the location of the user,
            only for as long as is necessary for the purposes set out in this
            Privacy Policy. This will help in tracing if some suspicious
            activity done.
          </li>
          <br />
          <li>
            Also mentioned in case of data breach, or hacking done on our
            portal, then oropocket is not responsible for that, in case of data
            stolen from oropocket, oropocket will inform you as early as it
            possible, so that user can secure their credentials.
          </li>
          <br />
          <li>
            These are the basic requirement for any body corporate as per
            Section 43A of the Information Technology Act (ITA).
          </li>
          <br />
          <br />
          If you are a resident of the European Economic Area (EEA), you have
          certain data protection rights. If you wish to be informed what
          Personal Information we hold about you and if you want it to be
          removed from our systems, please contact us.
          <br />
          <br />
          In certain circumstances, you have the following data protection
          rights:
          <br />
          <br />
          The right to access, update or to delete the information we have on
          you.
          <br />
          <br />
          <li>The right of rectification.</li>
          <br />
          <li>The right to object.</li>
          <br />
          <li> The right of restriction.</li>
          <br />
          <li>The right to data portability </li>
          <br />
          <li> The right to withdraw consent</li>
        </P>
        <Subtitle>Log Files</Subtitle>
        <P>
          Oropocket follows a standard procedure of using log files. These files
          log visitors when they visit websites. All hosting companies do this
          as part of hosting services' analytics. The information collected by
          log files include internet protocol (IP) addresses, browser type,
          Internet Service Provider (ISP), date and time stamp, referring/exit
          pages, and possibly the number of clicks. These are not linked to any
          information that is personally identifiable. The purpose of the
          information is for analyzing trends, administering the site, tracking
          users' movement on the website, and gathering demographic information.
        </P>
        <Subtitle>Cookies and Web Beacons</Subtitle>
        <P>
          Like any other website, Oropocket uses 'cookies'. These cookies are
          used to store information including visitors' preferences, and the
          pages on the website that the visitor accessed or visited. The
          information is used to optimize the users' experience by customizing
          our web page content based on visitors' browser type and/or other
          information.
          <br />
          <br />
          For more general information on cookies, please read "Cookies" article
          from the Privacy Policy Generator..
        </P>
        <Subtitle>Third Party Privacy Policies</Subtitle>
        <P>
          Oropocket's Privacy Policy does not apply to other advertisers or
          websites. Thus, we are advising you to consult the respective Privacy
          Policies of these third-party ad servers for more detailed
          information. It may include their practices and instructions about how
          to opt-out of certain options.
          <br />
          <br />
          You can choose to disable cookies through your individual browser
          options. To know more detailed information about cookie management
          with specific web browsers, it can be found at the browsers'
          respective websites
        </P>
        <Subtitle>Children's Information</Subtitle>
        <P>
          Another part of our priority is adding protection for children while
          using the internet. We encourage parents and guardians to observe,
          participate in, and/or monitor and guide their online activity.
          <br />
          <br />
          Oropocket does not knowingly collect any Personal Identifiable
          Information from children under the age of 13. If you think that your
          child provided this kind of information on our website, we strongly
          encourage you to contact us immediately and we will do our best
          efforts to promptly remove such information from our records.
        </P>
        <Subtitle>Online Privacy Policy Only</Subtitle>
        <P>
          Our Privacy Policy applies only to our online activities and is valid
          for visitors to our website with regards to the information that they
          shared and/or collected in Oropocket. This policy is not applicable to
          any information collected offline or via channels other than this
          website.
        </P>
        <Subtitle>Consent</Subtitle>
        <P>
          By using our website, you hereby consent to our Privacy Policy and
          agree to its terms.
        </P>
      </Container>
      <Footer />
    </Wrapper>
  );
}
