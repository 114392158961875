import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import FaqData from "./FAQ.json";

export const StyledAccordian = () => {
  return (
    <>
      {FaqData.map((data, ind) => {
        return (
          <Accordion
            style={{ padding: "10px 0" }}
            key={ind}
            defaultExpanded={ind === 0 ? true : false}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography
                style={{
                  fontSize: "18px",
                  fontFamily: "Roboto, sans-serif",
                  fontWeight: "600",
                  color: "#0F1F4A",
                }}
              >
                {data.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                style={{
                  fontFamily: "Roboto, sans-serif",
                  fontWeight: "400",
                }}
              >
                {data.description}
              </Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
};
