import React from "react";
import Copy from "../../assets/images/Copy.png";
import Calender from "../../assets/images/calender.png";
import Location from "../../assets/images/location.png";
// import EventBanner from "../../assets/images/eventBanner.png";
import EventBanner from "../../assets/images/eventOro.png";
import {
  BannerImage,
  CardHeader,
  CodeText,
  CodeWrapper,
  EventContentWrapper,
  EventDetailsWrapper,
  EventTag,
  Flexbox,
  HostTag,
  IconWrap,
  Image,
  LearnMore,
  LogoContainer,
  ModalContent,
  MoreText,
  SmallText,
  WebinarTag,
} from "./events.style";

// type EventDetails = {
//   id: number;
//   eventTitle: string;
//   eventDate: string;
//   eventLocation: string;
//   eventTag: string[];
//   eventBanner: string;
//   eventDes: string;
//   eventRegLink: string;
// }[];

const eventDetails: any = [
  {
    id: 1,
    eventTitle: "FiNext Conference",
    eventDate: "21 Jun 2023",
    eventLocation: "Le Méridien Dubai Hotel & Conference Centre",
    eventTag: ["Event"],
    eventBanner: EventBanner,
    eventDes:
      "FiNext Conference & Expo was initiated because the traditional finance industry needed a space where the smartest, inventive and ambitious people could come together, interact and build meaningful alliances.",
    eventRegLink: "https://finextcon.com/",
  },
];

const ModalCard = ({ data }: any) => {
  console.log(data, "card");
  const {
    eventBanner,
    eventDate,
    eventLocation,
    eventRegLink,
    eventTags,
    eventTitle,
    eventDes,
  } = eventDetails[0];

  return (
    <>
      <EventContentWrapper style={{ padding: "20px" }}>
        <div>
          <BannerImage src={eventBanner} />
        </div>
        <Flexbox>
          <EventTag>Event</EventTag>

          {/* {eventTags?.map((tag: any) => {
            return <EventTag>{tag}</EventTag>;
          })} */}
          {/* <WebinarTag>Webinar</WebinarTag>
          <HostTag>Host</HostTag> */}
        </Flexbox>
        <a href="https://finextcon.com/" target="_blank" rel="noopener">
          <CardHeader>{eventTitle}</CardHeader>
        </a>

        <EventDetailsWrapper>
          <div style={{ display: "flex" }}>
            <LogoContainer>
              <Image src={Calender} />
            </LogoContainer>
            <SmallText>{eventDate}</SmallText>
          </div>
          <div style={{ display: "flex" }}>
            <LogoContainer>
              <Image src={Location} />
            </LogoContainer>
            <SmallText>{eventLocation}</SmallText>
          </div>
          {/* <div style={{ display: "flex" }}>
              <LogoContainer>
                <Image src={Internet} />
              </LogoContainer>
              <SmallText>Visit Site</SmallText>
            </div> */}
        </EventDetailsWrapper>

        <ModalContent>{eventDes}</ModalContent>

        {/* <CodeWrapper>
          <CodeText>DAPPSBTD</CodeText>
          <IconWrap>
            <BannerImage src={Copy} />
          </IconWrap>
        </CodeWrapper> */}
        <a href={eventRegLink} target="_blank">
          <LearnMore>Register Here</LearnMore>
        </a>
      </EventContentWrapper>
    </>
  );
};

export default ModalCard;
